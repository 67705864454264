/*=========================================================================================
  File Name: moduleChatActions.js
  Description: Chat Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  storeRecommendedGroup({ commit }, group) {
    commit("UPDATE_RECOMMENDEDGROUPS", group);
  },
  storeGroupId({ commit }, ID) {
    commit("updateCurrentGroupID", ID);
  }
};
