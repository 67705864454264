/*=========================================================================================
  File Name: themeConfig.js
  Description: Theme configuration
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: hhttp://www.themeforest.net/user/pixinvent
==========================================================================================*/

// MAIN COLORS - VUESAX THEME COLORS
export let colors = {
  // primary: "#EA5455",
  primary: "#ec1f30",
  success: "#199D4E",
  danger: "#EA5455",
  warning: "#FF9F43",
  dark: "#114A8D",
  light: "#F4F5F6",
  "calendar-amber": "#fddf90",
  "calendar-green": "#cfeed9",
  "calendar-purple": "#e2d6ed",
  "calendar-blue": "#0d3e72",
  gray: "#7e7e7e",
  "calendar-fortnight": "#fce7c9",
};

import Vue from "vue";
import Vuesax from "vuesax";
Vue.use(Vuesax, { theme: { colors } });

// CONFIGS
const themeConfig = {
  disableCustomizer: true, // options[Boolean] : true, false(default)
  disableThemeTour: true, // options[Boolean] : true, false(default)
  footerType: "static", // options[String]  : static(default) / sticky / hidden
  hideScrollToTop: false, // options[Boolean] : true, false(default)
  mainLayoutType: "horizontal", // options[String]  : vertical(default) / horizontal
  navbarColor: "#fff", // options[String]  : HEX color / rgb / rgba / Valid HTML Color name - (default: #fff)
  navbarType: "floating", // options[String]  : floating(default) / static / sticky / hidden
  routerTransition: "zoom-fade", // options[String]  : zoom-fade / slide-fade / fade-bottom / fade / zoom-out / none(default)
  sidebarCollapsed: false, // options[Boolean] : true, false(default)
  theme: "light", // options[String]  : "light"(default), "dark", "semi-dark"

  // Not required yet - WIP
  userInfoLocalStorageKey: "userInfo",

  // NOTE: themeTour will be disabled in screens < 1200. Please refer docs for more info.
};

export default themeConfig;
