import Vue from "vue";
import { AclInstaller, AclCreate, AclRule } from "vue-acl";
import router from "@/router";
import jwt from "jsonwebtoken";

Vue.use(AclInstaller);

let initialRole = "admin";

let userInfo = jwt.decode(localStorage.getItem("userData"));

if (userInfo && userInfo.isCommunityOwner) {
  initialRole = "superadmin";
} else if (userInfo && userInfo.isCommunityManager) {
  initialRole = "admin";
} else if (userInfo && userInfo.isNormalUser) {
  initialRole = "normal";
} else {
  initialRole = "editor";
}

export default new AclCreate({
  initial: initialRole,
  notfound: "/home",
  router,
  acceptLocalRules: true,
  globalRules: {
    superadmin: new AclRule("superadmin").generate(),
    admin: new AclRule("admin").or("superadmin").generate(),
    editor: new AclRule("editor")
      .or("admin")
      .or("superadmin")
      .generate(),
    normal: new AclRule("normal")
      .or("editor")
      .or("admin")
      .or("superadmin")
      .generate()
    // public: new AclRule("public").or("admin").or("editor").generate(),
  }
});
