// import Repository from "./SecureRepository";
import LocalRepository from "./SecureRepository";
import csencrypt from "../config/csencrypt";

const addNotification = "BrainstormNotificationsApi/addNotification";
const getNotifications = "BrainstormNotificationsApi/getNotifications";
const updateNotification = "BrainstormNotificationsApi/updateNotification";
const getAllNotifications = "BrainstormNotificationsApi/getAllNotifications";

export default {
  async addNotification(payload) {
    payload.communityData.uniqueId = localStorage.getItem("biri");
    let { data } = await LocalRepository.post(
      `${addNotification}`,
      csencrypt.encrypt(JSON.stringify(payload))
    );

    return { data: csencrypt.decrypt(data) };
  },
  async getNotifications(payload) {
    payload.communityData.uniqueId = localStorage.getItem("biri");
    let { data } = await LocalRepository.post(
      `${getNotifications}`,
      csencrypt.encrypt(JSON.stringify(payload))
    );

    return { data: csencrypt.decrypt(data) };
  },
  async updateNotification(payload) {
    payload.communityData.uniqueId = localStorage.getItem("biri");
    let { data } = await LocalRepository.post(
      `${updateNotification}`,
      csencrypt.encrypt(JSON.stringify(payload))
    );

    return { data: csencrypt.decrypt(data) };
  },
  async getAllNotifications(payload) {
    payload.communityData.uniqueId = localStorage.getItem("biri");
    let { data } = await LocalRepository.post(
      `${getAllNotifications}`,
      csencrypt.encrypt(JSON.stringify(payload))
    );
    if (data.status === 500) {
      // alert("Your session expired");
      // localStorage.removeItem('token');
      // location.reload();
      // return false
    } else {
      return { data: csencrypt.decrypt(data) };
    }
  }
};
