import Repository from "@/Core/Apicall/Repository.js";

const resourceAddShortURL = "shortURLApi/addShortURL";
const resourceGetFullURL = "shortURLApi/getFullURL";
const resourceGetLastURL = "shortURLApi/getLastURL";

export default {
  addShortURL(payload) {
    return Repository.post(`${resourceAddShortURL}`, payload);
  },

  getFullURL(payload) {
    return Repository.post(`${resourceGetFullURL}`, payload);
  },

  getLastURL(payload) {
    return Repository.post(`${resourceGetLastURL}`, payload);
  }
};
