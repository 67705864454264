import shortURLRepository from "./shortURL.Repository";
import LDAPRepository from "./LDAP.repository";
import EmailSMSRepository from "./EmailSMSRepository";
import WhitelistRepository from "./Whitelist.Repository";
import NetDaniaRepository from "./NetDania.Repository";
import CalendarRepository from "./Calendar.Repository";
import NoteRepository from "./Note.Repository";

const repositories = {
  shortURL: shortURLRepository,
  LDAP: LDAPRepository,
  emailsms: EmailSMSRepository,
  whitelist: WhitelistRepository,
  economicCalendar: NetDaniaRepository,
  calendar: CalendarRepository,
  note: NoteRepository
};

export const ClientRepositoryFactory = {
  get: name => repositories[name]
};
