export default {
  allWhitelistedIPs(state) {
    return state.whiteListedIPs;
  },
  activatedIPs(state) {
    return state.whiteListedIPs.filter(x => x.status === true);
  },
  deactivatedIPs(state) {
    return state.whiteListedIPs.filter(x => x.status === false);
  }
};
