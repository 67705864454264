/*=========================================================================================
  File Name: moduleChatMutations.js
  Description: Chat Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import { xor } from "lodash";

export default {
  homeChannelPost(state, payload) {
    state.homeChannelPost = payload;
    state.totalPageBlog = payload.count / 6;
  },
  homeChannelPodcard(state, payload) {
    state.homeChannelPodcard = payload;
    state.totalPagePodcard = payload.count / 6;
  },
  postDetail(state, payload) {
    state.postDetail = payload;
  },
  channelPostDetail(state, payload) {
    state.channelPostDetail = payload;
  },
  objCommit(state, payload) {
    state.objCategory = payload;
  },
  objCommitPodcast(state, payload) {
    state.objCategoryPodcast = payload;
  },
  categorySlider(state, payload) {
    state.categorySlider = payload;
  },
  categorySliderPodcast(state, payload) {
    state.categorySliderPodcast = payload;
  },
  GET_CATEGORY(state, payload) {
    state.category = payload.reverse();
  },
  GET_AUTHOR(state, payload) {
    state.author = payload.reverse();
  },
  Scroll_top (state,payload){
    state.scroll=payload
  }
};
