export default // =============================================================================
// Client Pages
// =============================================================================

{
  path: "",
  component: () => import("./Core/layouts/main/Main.vue"),
  children: [
    {
      path: "/",
      //   redirect: "/pages/ApplicationPhase-1"
      // redirect: "/pages/not-authorized"
      redirect: "/home"
    },

    // =============================================================================
    // Pages Routes
    {
      path: "/pages/MIS",
      name: "page-MIS",
      component: () =>
        import(
          /* webpackChunkName: "mis" */ "@/Client/Kotak/pages/MIS/DashboardECommerce.vue"
        ),
      meta: {
        breadcrumb: [
          { title: "Home", url: "/" },
          { title: "Pages" },
          { title: "Profile", active: true }
        ],
        pageTitle: "Profile",
        rule: "editor",
        authRequired: true
      }
    },
    {
      path: "/pages/Block/author",
      name: "page-author",
      component: () =>
        import(
          /* webpackChunkName: "mis" */ "@/Client/Kotak/pages/Block/component/ManageAuthor.vue"
        ),
      meta: {
        breadcrumb: [
          { title: "Home", url: "/" },
          { title: "Pages" },
          { title: "Profile", active: true }
        ],
        pageTitle: "Profile",
        rule: "editor",
        authRequired: true
      }
    },
    {
      path: "/pages/Block/management",
      name: "page-management",
      component: () =>
        import(
          /* webpackChunkName: "mis" */ "@/Client/Kotak/pages/Block/component/Management.vue"
        ),
      meta: {
        breadcrumb: [
          { title: "Home", url: "/" },
          { title: "Pages" },
          { title: "Profile", active: true }
        ],
        pageTitle: "Profile",
        rule: "editor",
        authRequired: true
      }
    },
    {
      path: "/pages/Block/category",
      name: "page-Category",
      component: () =>
        import(
          /* webpackChunkName: "mis" */ "@/Client/Kotak/pages/Block/component/ManageCategory.vue"
        ),
      meta: {
        breadcrumb: [
          { title: "Home", url: "/" },
          { title: "Pages" },
          { title: "Profile", active: true }
        ],
        pageTitle: "Profile",
        rule: "editor",
        authRequired: true
      }
    },
    
    {
      path: "/community-member-list",
      name: "page-viewmembers",
      component: () =>
        import(
          /* webpackChunkName: "user-management" */ "@/Client/Kotak/pages/Members/ViewMembersMainPage.vue"
        ),
      meta: {
        title: "Kotak Treasury Community App - View Members",
        metaTags: [
          {
            property: "og:title",
            content: ""
          },
          {
            name: "description",
            content: ""
          },
          {
            property: "og:description",
            content: ""
          },
          {
            property: "og:image",
            content: `${window.location.href}/logo.png`
          }
        ],
        breadcrumb: [
          { title: "Home", url: "/" },
          { title: "Pages" },
          { title: "Profile", active: true }
        ],
        pageTitle: "Members",
        rule: "editor",
        authRequired: true
      }
    },
    {
      path: "/pages/WhitelistIP",
      name: "page-WhitelistIP",
      component: () =>
        import(
          /* webpackChunkName: "ip-utility" */ "@/Client/Kotak/pages/WhitelistIP/WhitelistIPMainPage.vue"
        ),
      meta: {
        rule: "editor",
        authRequired: true
      }
    }
  ]
};
