export default // =============================================================================
// Client Pages
// =============================================================================
{
  path: "",
  component: () => import("@/Core/layouts/full-page/FullPage.vue"),

  children: [
    {
      path: "/pages/customadminlogin",
      name: "page-login",
      component: () =>
        import(
          /* webpackChunkName: "admin-login" */ "@/Client/Kotak/pages/Login/Login.vue"
        ),
      meta: {
        rule: "editor",
      },
    },
   
    {
      path: "/pages/kotaklogin",
      name: "page-kotak-login",
      component: () =>
        import(
          /* webpackChunkName: "user-login" */ "@/Client/Kotak/pages/Login/UserLogin.vue"
        ),
      meta: {
        rule: "normal",
      },
    },
    {
      path: "/pages/customadminregister",
      name: "page-register",
      component: () =>
        import(
          /* webpackChunkName: "admin-register" */ "@/Client/Kotak/pages/Register/Register.vue"
        ),
      meta: {
        rule: "editor",
      },
    },
    {
      path: "/pages/kotakregister",
      name: "page-kotak-register",
      component: () =>
        import(
          /* webpackChunkName: "user-register" */ "@/Client/Kotak/pages/Register/UserRegister.vue"
        ),
      meta: {
        rule: "normal",
      },
    },
    {
      path: "/pages/customforgotpassword",
      name: "page-custom-forgot-password",
      component: () =>
        import(
          /* webpackChunkName: "admin-forgot-password" */ "@/Client/Kotak/pages/Forgot Password/ForgotPasswordKotak.vue"
        ),
      meta: {
        rule: "editor",
      },
    },
    {
      path: "/pages/PostNotFound",
      name: "page-Nopost",
      component: () =>
        import(
          /* webpackChunkName: "post-not-found" */ "@/Core/Views/pages/Nopost.vue"
        ),
      meta: {
        breadcrumb: [
          { title: "Home", url: "/" },
          { title: "Pages" },
          { title: "No Post", active: true },
        ],
        pageTitle: "No Post",
        rule: "editor",
      },
    },
    {
      path: "/calendar",
      name: "calendar",
      redirect: "calendar/fx",
      component: () =>
        import(
          /* webpackChunkName: "calendar" */ "@/Client/Kotak/pages/calendar/Calendar.vue"
        ),
      children: [
        {
          path: "economic",
          name: "economic-calendar",
          component: () =>
            import(
              /* webpackChunkName: "calendar-economic" */ "@/Client/Kotak/pages/calendar/views/calender-sec.vue"
            ),
          meta: {
            rule: "normal",
            authRequired: false,
            title: "Economic Calendar",
          },
        },
        {
          path: "money-market",
          name: "money-market-calendar",
          component: () =>
            import(
              /* webpackChunkName: "calendar-mm" */ "@/Client/Kotak/pages/calendar/views/MoneyMarketCalendar.vue"
            ),
          meta: {
            rule: "normal",
            authRequired: false,
            title: "Money Market Calendar",
          },
        },
        {
          path: "money-market/import",
          name: "money-market-import",
          component: () =>
            import(
              /* webpackChunkName: "calendar-import" */ "@/Client/Kotak/pages/calendar/views/ImportMoneyMarketView.vue"
            ),
          meta: {
            rule: "editor",
            authRequired: true,
          },
        },
        {
          path: "fx",
          name: "fx-calendar",
          component: () =>
            import(
              /* webpackChunkName: "calendar-fx" */ "@/Client/Kotak/pages/calendar/views/FXCalendar.vue"
            ),
          meta: {
            rule: "normal",
            authRequired: false,
            title: "FX Calendar",
          },
        },
        {
          path: "fx/:year/:month/:day",
          name: "fx-calendar-day",
          component: () =>
            import(
              /* webpackChunkName: "calendar-fx-day" */ "@/Client/Kotak/pages/calendar/views/FXCalendarDay.vue"
            ),
          meta: {
            rule: "editor",
            authRequired: false,
            title: "FX Calendar",
          },
        },
        {
          path: "fx/import",
          name: "fx-import",
          component: () =>
            import(
              /* webpackChunkName: "calendar-import" */ "@/Client/Kotak/pages/calendar/views/ImportFXView.vue"
            ),
          meta: {
            rule: "editor",
            authRequired: true,
            title: "FX Calendar",
          },
        },
        // {
        //   path: "sec",
        //   name: "fx-calendarsec",
        //   component: () =>
        //     import("@/Client/Kotak/pages/calendar/views/calender-sec.vue"),
        //   meta: {
        //     rule: "editor",
        //     authRequired: false,
        //     title: "FX Calendar"
        //   }
        // }
      ],

      meta: {
        rule: "editor",
        authRequired: true,
      },
    },
    {
      path: "/overseer/utilities",
      name: "overseer-utilities",
      component: () =>
        import(
          /* webpackChunkName: "utilities" */ "@/Client/Kotak/pages/Utilities/views/Utilities.vue"
        ),
      meta: {
        rule: "normal",
      },
    },
  ],
};
