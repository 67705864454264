export default {
  SET_EVENTS(state, payload) {
    if (!payload.length) return;
    const result = [];
    const future = [];
    payload.forEach(element => {
      //The body of the news is an object with multiple elements
      //f1 - Event description eg "USD ISM Non-Manufacturing PMI"
      //f10 - Parent event ID eg: 330
      //f12 - Event date(seconds) eg: 1423062000
      //f13 - Event real date(seconds) eg: 1420070400
      //f14 - Recurrence Type eg: "month" or "week"
      //f15 - Recurrence Period   eg: 1
      //f3 - Importance(string) eg: "High"
      //f4 - Country(string) eg: "UNITED STATES"
      //f5 - Currency (string) eg: "USD"
      //f6 - Previous value eg: "56.2"
      //f7 - Actual value eg: "56.2"
      //f8 - Forecast value eg: "56.2"
      const texts = JSON.parse(element._text);
      const attributes = element._attributes;
      // const labelIndex = state.eventLabels.findIndex(x => x.text === texts.f3);
      // const eventIndex = state.events.findIndex(x => x.id === attributes.id);
      // if (eventIndex === -1) {
      // result.push({
      //   id: attributes.id,
      //   title: texts.f1,
      //   startDate: attributes.time,
      //   endDate: attributes.time,
      //   label: texts.f3,
      //   url: "",
      //   classes: `event-${state.eventLabels[labelIndex].color} mr-1 economic`,
      //   otherDetails: texts
      // });
      const data = {
        description: texts.f1,
        parentID: texts.f10,
        date: texts.f12,
        realDate: texts.f13,
        recurrenceType: texts.f14,
        recurrencePeriod: texts.f15,
        importance: texts.f3,
        country: texts.f4,
        currency: texts.f5,
        previousValue: texts.f6,
        actualValue: texts.f7,
        forecastValue: texts.f8,
        isStory: attributes.story,
        storyID: attributes.id
      };
      if (texts.f9) {
        data.valueType = texts.f9;
      }
      result.push(data);
      const time = new Date().getTime() / 1000;
      if (texts.f12 > time) {
        future.push({
          description: texts.f1,
          parentID: texts.f10,
          date: texts.f12,
          realDate: texts.f13,
          recurrenceType: texts.f14,
          recurrencePeriod: texts.f15,
          importance: texts.f3,
          country: texts.f4,
          currency: texts.f5,
          previousValue: texts.f6,
          actualValue: texts.f7,
          forecastValue: texts.f8,
          isStory: attributes.story,
          storyID: attributes.id
        });
      }
      // }
    });
    // if (state.events.length) {
    // state.events = [...state.events, ...result];
    // } else {
    state.events = result;
    if (future.length) {
      state.futureEvents.length
        ? (state.futureEvents = [...state.futureEvents, ...future])
        : (state.futureEvents = future);
    }
    // }
  },

  SET_MONEY_MARKET_EVENTS(state, payload) {
    if (!payload.length) return;
    const result = [];
    payload.forEach(element => {
      const eventIndex = state.moneyMarketEvents.findIndex(
        x => x._id === element._id
      );

      if (eventIndex === -1) {
        result.push({
          ...element,
          classes: `event-calendar-amber mr-1`
        });
      }
    });
    if (state.moneyMarketEvents.length) {
      state.moneyMarketEvents = [...state.moneyMarketEvents, ...result];
    } else {
      state.moneyMarketEvents = result;
    }
  },

  SET_FX_EVENTS(state, payload) {
    if (!payload.length) return;

    if (state.fxEvents.length) {
      state.fxEvents = [...state.fxEvents, ...payload];
    } else {
      state.fxEvents = payload;
    }
  },

  SET_EVENT_NOTIFICATIONS(state, payload) {
    if (!payload.length) return;
    state.eventNotifications = payload;
  },

  SET_ACTUAL_NOTIFICATIONS(state, payload) {
    if (!payload.length) return;
    state.actualNotifications = payload;
  },

  APPEND_EVENT_NOTIFICATION(state, payload) {
    state.eventNotifications.push(payload);
  },

  REMOVE_EVENT_NOTIFICATION(state, payload) {
    if (!payload) return;
    const index = state.eventNotifications.findIndex(x => x._id === payload);
    if (index === -1) return;
    state.eventNotifications.splice(index, 1);
  }
};
