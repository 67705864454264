import { ClientRepositoryFactory } from "@/Client/Kotak/kotakapicall/ClientRepositoryFactory";
const NetDaniaRepository = ClientRepositoryFactory.get("economicCalendar");
const CalendarRepository = ClientRepositoryFactory.get("calendar");

export default {
  getHeadLines({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await NetDaniaRepository.getHeadLines(payload);

        if (data.datafeed && data.datafeed.headline) {
          commit("SET_EVENTS", data.datafeed.headline);
        }

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  getHistory({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await NetDaniaRepository.getHistory(payload);

        resolve(data.datafeed.story);
      } catch (error) {
        reject(error);
      }
    });
  },

  getSearch({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await NetDaniaRepository.getSearch(payload);

        if (data.datafeed && data.datafeed.headline) {
          commit("SET_EVENTS", data.datafeed.headline);
        }

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  importMoneyMarket({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await CalendarRepository.importMoneyMarket(payload);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  importFX({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await CalendarRepository.importFX(payload);

        // if (data.length) {
        //   commit("SET_FX_EVENTS", data);
        // }

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  getMoneyMarket({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await CalendarRepository.getMoneyMarket(payload);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  getFX({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await CalendarRepository.getFX(payload);

        // if (data.length) {
        //   commit("SET_FX_EVENTS", data);
        // }

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  importMarketLastYear({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await CalendarRepository.importMarketLastYear(payload);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  importInternationalData({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await CalendarRepository.importInternationalData(
          payload
        );

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  getNotifications({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await CalendarRepository.getNotifications(payload);
        if (data.length) {
          commit("SET_EVENT_NOTIFICATIONS", data);
        }
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  addNotification({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await CalendarRepository.addNotification(payload);
        if (data.length) {
          commit("APPEND_EVENT_NOTIFICATION", data[0]);
        }
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  removeNotification({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await CalendarRepository.removeNotification(payload);

        commit("REMOVE_EVENT_NOTIFICATION", payload.id);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  }
};
