/*=========================================================================================
  File Name: main.js
  Description: main vue(js) file
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from "vue";
import App from "./App.vue";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "@/Core/Views/Commoncss/common.css";
import "@/Core/assets/css/index.scss";
//Vue Croppper

// Vuesax Component Framework
import Vuesax from "vuesax";
import "material-icons/iconfont/material-icons.css"; //Material Icons
import "vuesax/dist/vuesax.css"; // Vuesax
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
Vue.use(Vuesax);

// axios
import axios from "./axios.js";
Vue.prototype.$http = axios;
//import csencrypt from "./Core/config/csencrypt";

// Theme Configurations
import "../themeConfig.js";

// Auth0 Plugin
// import AuthPlugin from "./plugins/auth";
// Vue.use(AuthPlugin);

// ACL
import acl from "@/Core/acl/acl";

// Globally Registered Components
import "./globalComponents.js";

// Styles: SCSS
import "@/Core/assets/scss/main.scss";

// Tailwind
import "@/Core/assets/css/main.css";

// Vue Router
import router from "./router";

// Vuex Store
import store from "@/Core/store/store";

// Clipboard
import VueClipboard from "vue-clipboard2";
Vue.use(VueClipboard);

// VeeValidate
import VeeValidate from "vee-validate";
Vue.use(VeeValidate, { events: "change|blur" });

// Vuejs - Vue wrapper for hammerjs
import { VueHammer } from "vue2-hammer";
Vue.use(VueHammer);

import VueSession from "vue-session";
Vue.use(VueSession, { persist: true });

// PrismJS
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";

// Feather font icon
require("./Core/assets/css/iconfont.css");

require("./Core/assets/css/montserratFont.css");
require("./Core/assets/css/openSansFont.css");

// Vue select css
// Note: In latest version you have to add it separately
// import 'vue-select/dist/vue-select.css';

import InfiniteLoading from "vue-infinite-loading";
Vue.use(InfiniteLoading, {
  /* options */
});

import VueHorizontal from "vue-horizontal";

Vue.use(VueHorizontal);

import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
const options = {
  confirmButtonColor: `rgba(var(--vs-primary), 1)`,
  cancelButtonColor: "#ff7674",
};
Vue.use(VueSweetalert2, options);

import "./registerServiceWorker";

var SocialSharing = require("vue-social-sharing");
Vue.use(SocialSharing);

import VueFuse from "vue-fuse";
Vue.use(VueFuse);

import VueMeta from "vue-meta";
Vue.use(VueMeta);

// import { VueReCaptcha } from "vue-recaptcha-v3";
// Vue.use(VueReCaptcha, { siteKey: "6LfnOccZAAAAANp4wJEmQeFfDVIc80b0KcipIfG0" });

//V-Idle
import Vidle from "v-idle";
Vue.use(Vidle);

import VxTooltip from "@/Core/components/vx-tooltip/VxTooltip.vue";
Vue.component(VxTooltip.name, VxTooltip);

// Import Bootstrap an BootstrapVue CSS files (order is important)
// import 'bootstrap/dist/css/bootstrap.css'
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

Vue.mixin({
  data: function () {
    return {
      get baseUrlMagitt() {
        return process.env.VUE_APP_BASE_URL_COR_API;
        //  return "http://localhost:9890/";
        //  return "http://35.207.243.82/";
      },
    };
  },
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  acl,
  // session,
  render: (h) => h(App),
}).$mount("#app");
