import axios from "axios";

// You can use your own logic to set your local or production domain
// const baseDomain = "https://jsonplaceholder.typicode.com";
const baseDomain = process.env.VUE_APP_BASE_URL_COR_API;
// const baseDomain = "http://localhost:9890/";
//const baseDomain = "http://35.207.243.82/";
// The base URL is empty this time due we are using the jsonplaceholder API
const baseURL = `${baseDomain}`;

export default axios.create({
  baseURL,
  // headers: {
  //   "Cache-Control": ["no-cache", "maxage=0", "no-store"],
  //   // "Cache-Control": "no-cache 	no-store max-age",
  // },
});
