export default {
  addUser(state, user) {
    const index = state.invitedUsers.findIndex(x => x.UID === user.UID);
    state.invitedUsers[index].isSelected = true;
  },
  removeUser(state, user) {
    const index = state.invitedUsers.findIndex(x => x.UID === user.UID);
    state.invitedUsers[index].isSelected = false;
  },
  selectAll(state) {
    state.invitedUsers.map(x => (x.isSelected = true));
  },
  unselectAll(state) {
    state.invitedUsers.map(x => (x.isSelected = false));
  },
  resetUsers(state) {
    state.invitedUsers = [];
  },
  setUsers(state, users) {
    state.invitedUsers = users;
  }
};
