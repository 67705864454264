import Repository from "@/Core/Apicall/Repository.js";

const resourceCheckWhitelisted = "whitelistApi/checkWhitelisted";
const resourceAddNewIP = "whitelistApi/addNewIP";
const resourceGetIPs = "whitelistApi/getIPs";
const resourceUpdateIP = "whitelistApi/updateIP";
const resourceDeleteIP = "whitelistApi/deleteIP";
const resourceUpdateStatus = "whitelistApi/updateStatus";

export default {
  checkWhitelisted() {
    return Repository.get(`${resourceCheckWhitelisted}`);
  },
  addNewIP(payload) {
    return Repository.post(`${resourceAddNewIP}`, payload);
  },
  getIPs() {
    return Repository.get(`${resourceGetIPs}`);
  },
  updateIP(payload) {
    return Repository.post(`${resourceUpdateIP}`, payload);
  },
  deleteIP(payload) {
    return Repository.post(`${resourceDeleteIP}`, payload);
  },
  updateStatus(payload) {
    return Repository.post(`${resourceUpdateStatus}`, payload);
  }
};
