import Repository from "@/Core/Apicall/Repository.js";

const resourceHeadlines = "kotak/netdania/news_headlines";
const resourceSearch = "kotak/netdania/news_search";
const resourceStory = "kotak/netdania/news_story";
const resourceHistory = "kotak/netdania/news_history";

export default {
  getHeadLines(payload) {
    return Repository.post(`${resourceHeadlines}`, payload);
  },
  getSearch(payload) {
    return Repository.post(`${resourceSearch}`, payload);
  },
  getStory(payload) {
    return Repository.post(`${resourceStory}`, payload);
  },
  getHistory(payload) {
    return Repository.post(`${resourceHistory}`, payload);
  }
};
