import Vue from "vue";
import Router from "vue-router";
import ClientRouter from "./ClientRouter";
import ClientFullPageRouter from "./ClientFullPageRouter";
Vue.use(Router);

import jwt from "jsonwebtoken";

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },

  routes: [
    ClientRouter,
    
    {
      // =============================================================================
      // MAIN LAYOUT ROUTES
      // =============================================================================
      path: "",
      component: () => import("./Core/layouts/main/Main.vue"),
      children: [
      // ClientFullPageRouter,
        {
          path: "/",
          //   redirect: "/pages/ApplicationPhase-1"
          redirect: "/pages/kotaklogin",
        },

        // =============================================================================
        // Pages Routes
        // =============================================================================
        {
          path: "/home",
          name: "latest-home",
          component: () =>
            import("@/Core/Views/pages/latest/homepage/Home.vue"),
          meta: {
            title: "Home",

            rule: "editor",
            // authRequired: true,
          },
        },
        {
          path: "/blog-detail",
          name: "latest-economy",
          component: () =>
            import("@/Core/Views/pages/latest/economy/Economy.vue"),
          meta: {
            title: "Economy",

            rule: "editor",
            authRequired: true,
          },
        },
        {
          path: "/readers-view",
          name: "readers-view",
          component: () =>
            import("@/Core/Views/pages/group/readers-view/EconomyView.vue"),
          meta: {
            // title: "readers",
               rule: "normal",
            // rule: "editor",
            // authRequired: true,
          },
        },
    //     {
    //   path: "/calendar",
    //   name: "calendar",
    //   redirect: "calendar/fx",
    //   component: () =>
    //     import(
    //       /* webpackChunkName: "calendar" */ "@/Client/Kotak/pages/calendar/Calendar.vue"
    //     ),
    //   children: [
    //     {
    //       path: "economic",
    //       name: "economic-calendar",
    //       component: () =>
    //         import(
    //           /* webpackChunkName: "calendar-economic" */ "@/Client/Kotak/pages/calendar/views/calender-sec.vue"
    //         ),
    //       meta: {
    //         rule: "normal",
    //         authRequired: false,
    //         title: "Economic Calendar",
    //       },
    //     },
    //     {
    //       path: "money-market",
    //       name: "money-market-calendar",
    //       component: () =>
    //         import(
    //           /* webpackChunkName: "calendar-mm" */ "@/Client/Kotak/pages/calendar/views/MoneyMarketCalendar.vue"
    //         ),
    //       meta: {
    //         rule: "normal",
    //         authRequired: false,
    //         title: "Money Market Calendar",
    //       },
    //     },
    //     {
    //       path: "money-market/import",
    //       name: "money-market-import",
    //       component: () =>
    //         import(
    //           /* webpackChunkName: "calendar-import" */ "@/Client/Kotak/pages/calendar/views/ImportMoneyMarketView.vue"
    //         ),
    //       meta: {
    //         rule: "editor",
    //         authRequired: true,
    //       },
    //     },
    //     {
    //       path: "fx",
    //       name: "fx-calendar",
    //       component: () =>
    //         import(
    //           /* webpackChunkName: "calendar-fx" */ "@/Client/Kotak/pages/calendar/views/FXCalendar.vue"
    //         ),
    //       meta: {
    //         rule: "normal",
    //         authRequired: false,
    //         title: "FX Calendar",
    //       },
    //     },
    //     {
    //       path: "fx/:year/:month/:day",
    //       name: "fx-calendar-day",
    //       component: () =>
    //         import(
    //           /* webpackChunkName: "calendar-fx-day" */ "@/Client/Kotak/pages/calendar/views/FXCalendarDay.vue"
    //         ),
    //       meta: {
    //         rule: "editor",
    //         authRequired: false,
    //         title: "FX Calendar",
    //       },
    //     },
    //     {
    //       path: "fx/import",
    //       name: "fx-import",
    //       component: () =>
    //         import(
    //           /* webpackChunkName: "calendar-import" */ "@/Client/Kotak/pages/calendar/views/ImportFXView.vue"
    //         ),
    //       meta: {
    //         rule: "editor",
    //         authRequired: true,
    //         title: "FX Calendar",
    //       },
    //     },
    //     // {
    //     //   path: "sec",
    //     //   name: "fx-calendarsec",
    //     //   component: () =>
    //     //     import("@/Client/Kotak/pages/calendar/views/calender-sec.vue"),
    //     //   meta: {
    //     //     rule: "editor",
    //     //     authRequired: false,
    //     //     title: "FX Calendar"
    //     //   }
    //     // }
    //   ],

    //   meta: {
    //     rule: "editor",
    //     authRequired: true,
    //   },
    // },
        {
          path: "/community-home",
          name: "community-home",
          component: () =>
            import(
              /* webpackChunkName: "home" */ "@/Core/Views/pages/home/Carousel.vue"
            ),

          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Community" },
              { title: "Home", active: true },
            ],
            title: "Kotak Treasury",
            metaTags: [
              {
                property: "og:title",
                content: "Kotak Treasury",
              },
              {
                name: "description",
                content: "",
              },
              {
                property: "og:description",
                content: "",
              },
              {
                property: "og:image",
                content: `${window.location.href}/logo.png`,
              },
            ],
            pageTitle: "Carousel",
            rule: "editor",
            authRequired: true,
          },
        },
        {
          path: "/community-channels",
          name: "page-GroupsPage",
          component: () =>
            import(
              /* webpackChunkName: "channel" */ "@/Core/Views/pages/group/GroupsPage.vue"
            ),
          meta: {
            title: "Kotak Treasury Channels",
            metaTags: [
              {
                property: "og:title",
                content: "Kotak Treasury Channels",
              },
              {
                name: "description",
                content: "",
              },
              {
                property: "og:description",
                content: "",
              },
              {
                property: "og:image",
                content: `${window.location.href}/logo.png`,
              },
            ],
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Pages" },
              { title: "Profile", active: true },
            ],
            pageTitle: "Profile",
            rule: "editor",
            authRequired: true,
          },
        },
        {
          path: "/community-user-profile",
          name: "page-profile",
          component: () =>
            import(
              /* webpackChunkName: "profile" */ "@/Core/Views/pages/profile/Profile.vue"
            ),
          meta: {
            title: "Kotak Treasury - User Profiles",
            metaTags: [
              {
                property: "og:title",
                content: "Kotak Treasury - User Profiles",
              },
              {
                name: "description",
                content: "",
              },
              {
                property: "og:description",
                content: "",
              },
              {
                property: "og:image",
                content: `${window.location.href}/logo.png`,
              },
            ],
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Pages" },
              { title: "Profile", active: true },
            ],
            pageTitle: "Profile",
            rule: "editor",
            authRequired: true,
          },
        },
        {
          path: "/pages/excelinvites",
          name: "page-excelinvites",
          component: () =>
            import(
              /* webpackChunkName: "invite" */ "@/Core/Views/pages/members/ExcelInviteMainPage.vue"
            ),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Pages" },
              { title: "Profile", active: true },
            ],
            pageTitle: "Profile",
            rule: "editor",
            authRequired: true,
          },
        },
      ],
    },

    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    ClientFullPageRouter,
    {
      path: "",
      component: () => import("@/Core/layouts/full-page/FullPage.vue"),

      children: [
        // =============================================================================
        // PAGES
        // =============================================================================

        // {
        //   path: "/pages/login",
        //   name: "page-login",
        //   component: () => import("@/Core/Views/DashBoardHome/login.vue"),
        //   meta: {
        //     rule: "editor"
        //   }
        // },
        // {
        //   path: "/pages/register",
        //   name: "page-register",
        //   component: () => import("@/Core/Views/pages/register/Register.vue"),
        //   meta: {
        //     rule: "editor"
        //   }
        // },
        // {
        //   path: "/pages/forgot-password",
        //   name: "page-forgot-password",
        //   component: () => import("@/Core/Views/pages/ForgotPassword.vue"),
        //   meta: {
        //     rule: "editor"
        //   }
        // },
        // {
        //   path: "/pages/reset-password",
        //   name: "page-reset-password",
        //   component: () => import("@/Core/Views/pages/ResetPassword.vue"),
        //   meta: {
        //     rule: "editor"
        //   }
        // },
        // {
        //   path: "/pages/lock-screen",
        //   name: "page-lock-screen",
        //   component: () => import("@/Core/Views/pages/LockScreen.vue"),
        //   meta: {
        //     rule: "editor"
        //   }
        // },
        // {
        //   path: "/pages/comingsoon",
        //   name: "page-coming-soon",
        //   component: () => import("@/Core/Views/pages/ComingSoon.vue"),
        //   meta: {
        //     rule: "editor"
        //   }
        // },
        {
          path: "/pages/error-404",
          name: "page-error-404",
          component: () => import("@/Core/Views/pages/Error404.vue"),
          meta: {
            rule: "editor",
          },
        },
        // {
        //   path: "/readers-view",
        //   name: "readers-view",
        //   component: () =>
        //     import("@/Core/Views/pages/group/readers-view/EconomyView.vue"),
        //   meta: {
        //     // title: "readers",
        //        rule: "normal",
        //     // rule: "editor",
        //     // authRequired: true,
        //   },
        // },
        // {
        //   path: "/pages/error-500",
        //   name: "page-error-500",
        //   component: () => import("@/Core/Views/pages/Error500.vue"),
        //   meta: {
        //     rule: "editor"
        //   }
        // },
        {
          path: "/pages/not-authorized",
          name: "page-not-authorized",
          component: () => import("@/Core/Views/pages/NotAuthorized.vue"),
          meta: {
            rule: "editor",
          },
        },
        // {
        //   path: "/pages/maintenance",
        //   name: "page-maintenance",
        //   component: () => import("@/Core/Views/pages/Maintenance.vue"),
        //   meta: {
        //     rule: "editor"
        //   }
        // },
        {
          path: "/pages/terms",
          name: "page-terms",
          component: () =>
            import(
              /* webpackChunkName: "terms" */ "@/Core/Views/pages/Terms.vue"
            ),
          meta: {
            rule: "normal",
          },
        },
        {
          path: "/posts",
          name: "page-IndividualPost",
          component: () =>
            import(
              /* webpackChunkName: "post" */ "@/Core/Views/pages/IndividualPost/IndividualPost.vue"
            ),
          meta: {
            rule: "normal",
          },
        },
        {
          path: "/posts/:id",
          name: "page-IndividualPost-id",
          component: () =>
            import(
              /* webpackChunkName: "post" */ "@/Core/Views/pages/IndividualPost/IndividualPost.vue"
            ),
          meta: {
            rule: "normal",
          },
        },
      ],
    },

    {
      path: "",
      component: () => import("./Core/layouts/main/Main.vue"),
      children: [
        // =============================================================================
        // PAGES
        // =============================================================================
        // {
        //   path: "/pages/contact",
        //   name: "page-profile",
        //   component: () =>
        //     import("@/Core/Views/DashBoardHome/ContactMainPage.vue"),
        //   meta: {
        //     pageTitle: "Profile",
        //     rule: "editor"
        //   }
        // }
      ],
    },
    // Redirect to 404 page, if no match found
    {
      path: "*",
      redirect: "/pages/error-404",
    },
  ],
});

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

router.beforeEach((to, from, next) => {
  // if (
  //     to.path === "/pages/login" ||
  //     to.path === "/pages/forgot-password" ||
  //     to.path === "/pages/error-404" ||
  //     to.path === "/pages/error-500" ||
  //     to.path === "/pages/register" ||
  //     to.path === "/callback" ||
  //     to.path === "/pages/comingsoon" ||
  //     (auth.isAuthenticated())
  // ) {
  //     return next();
  // }

  // If auth required, check login. If login fails redirect to login page
  if (to.meta.authRequired) {
    // console.log(localStorage.getItem("biri"));
    // console.log(localStorage.getItem("token"));

    if (localStorage.getItem("token")) {
      jwt.verify(
        localStorage.getItem("token"),
        process.env.VUE_APP_RAAZKIBAAT + localStorage.getItem("biri"),
        async (err) => {
          if (err) {
            // if (err.name === "TokenExpiredError") {
            //   // handle error
            // } else {
            router.push({ path: "/", query: { to: to.path } });
            // }
          } else {
            return;
          }
        }
      );
    } else {
      router.push({ path: "/", query: { to: to.path } });
    }

    // if (
    //   !(
    //     localStorage.getItem("token") &&
    //     jwt.verify(
    //       localStorage.getItem("token"),
    //       process.env.VUE_APP_RAAZKIBAAT + localStorage.getItem("biri")
    //     )
    //   )
    // ) {

    // }
  }

  return next();
  // Specify the current path as the customState parameter, meaning it
  // will be returned to the application after auth
  // auth.login({ target: to.path });
  // });
});

export default router;
