import Repository from "./Repository";
// import LocalRepository from "./LocalRepository";

const resourceAddNewCommunity = "/BrainstormApi/CommunitiesSignup";
const resourceCreateNewCommunity = "/BrainstormApi/createNewCommunity";
const resourceCheckCommunity = "/BrainstormApi/checkCommunity";
const resourceSendSMS = "/SendMailApi/newsendsms";

export default {
  addNewCommunity(payload) {
    return Repository.post(`${resourceAddNewCommunity}`, payload);
  },
  createNewCommunity(payload) {
    return Repository.post(`${resourceCreateNewCommunity}`, payload);
  },
  checkCommunity(payload) {
    return Repository.post(`${resourceCheckCommunity}`, payload);
  },
  sendSMS(payload) {
    return Repository.post(`${resourceSendSMS}`, payload);
  }
};
