import { ClientRepositoryFactory } from "@/Client/Kotak/kotakapicall/ClientRepositoryFactory";
const whitelistRepository = ClientRepositoryFactory.get("whitelist");

export default {
  async addIP({ commit }, payload) {
    const { data } = await whitelistRepository.addNewIP(payload);
    commit("addIP", data);
    return { data };
  },
  async getIPs({ commit }) {
    const { data } = await whitelistRepository.getIPs();

    commit("setData", data);
  },
  async updateIP({ commit }, payload) {
    const { data } = await whitelistRepository.updateIP(payload);
    const update = {
      id: payload.id,
      key: "ipAddress",
      value: payload.newIPAddress
    };
    commit("updateData", update);
    return { data };
  },
  async deleteIP({ commit }, payload) {
    const { data } = await whitelistRepository.deleteIP(payload);
    commit("deleteIP", payload);
    return { data };
  },
  async updateStatus({ commit }, payload) {
    const { data } = await whitelistRepository.updateStatus(payload);
    const update = {
      id: payload.id,
      key: "status",
      value: payload.status
    };
    commit("updateData", update);
    return { data };
  },
  resetIPs({ commit }) {
    commit("resetData");
  },
  async checkWhitelisted({}) {
    const { data } = await whitelistRepository.checkWhitelisted();
    return { data };
  }
};
