import Repository from "./SecureRepository";
// import LocalRepository from "./LocalRepository";

const getAllHandles = "BrainstormGroupsApi/getAllHandles";
const getAllGlobalHandles = "BrainstormHandlesApi/getAllGlobalHandles";
const searchAllHandles = "BrainstormGroupsApi/searchAllHandles";
const addGlobalHandle = "BrainstormHandlesApi/addGlobalHandle";
const addGlobalHandleCommunityMapping =
  "BrainstormHandlesApi/addGlobalHandleCommunityMapping";
const addHandle = "BrainstormHandlesApi/addHandle";
const addHandleAdmin = "BrainstormHandlesApi/HandleAdmin";
const followHandle = "BrainstormHandlesApi/followHandle";
const editFollowHandle = "BrainstormHandlesApi/editFollowHandle";
const getRecommendedHandles = "BrainstormHandlesApi/getRecommendedHandles";

export default {
  getAllHandles(payload) {
    return Repository.post(`${getAllHandles}`, payload);
  },
  getAllGlobalHandles(payload) {
    return Repository.post(`${getAllGlobalHandles}`, payload);
  },
  searchAllHandles(payload) {
    return Repository.post(`${searchAllHandles}`, payload);
  },
  addGlobalHandle(payload) {
    return Repository.post(`${addGlobalHandle}`, payload);
  },
  addGlobalHandleCommunityMapping(payload) {
    return Repository.post(`${addGlobalHandleCommunityMapping}`, payload);
  },
  addHandle(payload) {
    return Repository.post(`${addHandle}`, payload);
  },
  addHandleAdmin(payload) {
    return Repository.post(`${addHandleAdmin}`, payload);
  },
  followHandle(payload) {
    return Repository.post(`${followHandle}`, payload);
  },
  editFollowHandle(payload) {
    return Repository.post(`${editFollowHandle}`, payload);
  },
  getRecommendedHandles(payload) {
    return Repository.post(`${getRecommendedHandles}`, payload);
  }
};
