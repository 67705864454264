import state from "./moduleUtilitiesState.js";
import mutations from "./moduleUtilitiesMutations.js";
import actions from "./moduleUtilitiesActions.js";
import getters from "./moduleUtilitiesGetters.js";

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
