import csencrypt from "../config/csencrypt";
import Repository from "./SecureRepository";

const resourcegetUserProfileData = "BrainstormUserSettingsApi/getUserProfile";
const resourcegetgetFieldData = "BrainstormUserSettingsApi/getFieldData";
const resourcesaveUserProfileSettings =
  "BrainstormUserSettingsApi/editUserProfile";
const resourcegetAllUserData = "BrainstormUserSettingsApi/getAllUserData";
const resourceeditUserProfilePicture =
  "/BrainstormUserSettingsApi/editUserProfilePicture";
const resourceeditUserProfileCoverPicture =
  "/BrainstormUserSettingsApi/editUserProfileCoverPicture";
const getUserProfileDataConnectyCube =
  "/BrainstormUserSettingsApi/getUserProfileConnectyCubeId";
const resourceeditUserProfileCRN =
  "BrainstormUserSettingsApi/editUserProfileCRN";
const resourceSendRequestRoleChange = "BrainstormUserSettingsApi/sendRequest";
const resourceCheckldap = "BrainstormUserSettingsApi/checkldap";
const resourceacceptRequestRoleChange =
  "BrainstormUserSettingsApi/acceptRequest";
const resourcerejectRequestRoleChange =
  "BrainstormUserSettingsApi/rejectRequest";
const getPendingRequestRoleChange = "BrainstormUserSettingsApi/getRequest";
export default {
  async getUserProfileData(payload) {
    payload.communityData = {
      communityName: "kotak treasury",
      uniqueId: localStorage.getItem("biri"),
    };

    const { data } = await Repository.post(
      `${resourcegetUserProfileData}`,
      csencrypt.encrypt(JSON.stringify(payload))
    );
    return { data: csencrypt.decrypt(data) };
  },
  async getUserProfileDataConnectyCube(payload) {
    payload.communityData = {
      communityName: "kotak treasury",
      uniqueId: localStorage.getItem("biri"),
    };
    const { data } = await Repository.post(
      `${getUserProfileDataConnectyCube}`,
      csencrypt.encrypt(JSON.stringify(payload))
    );
    return { data: csencrypt.decrypt(data) };
  },
  async getFieldData(payload) {
    payload.communityData = {
      communityName: "kotak treasury",
      uniqueId: localStorage.getItem("biri"),
    };
    const { data } = await Repository.post(
      `${resourcegetgetFieldData}`,
      csencrypt.encrypt(JSON.stringify(payload))
    );
    return { data: csencrypt.decrypt(data) };
  },
  async saveUserProfileSettings(payload) {
    payload.communityData = {
      communityName: "kotak treasury",
      uniqueId: localStorage.getItem("biri"),
    };

    const { data } = await Repository.post(
      `${resourcesaveUserProfileSettings}`,
      csencrypt.encrypt(JSON.stringify(payload))
    );
    return { data: csencrypt.decrypt(data) };
  },

  async getAllUserData(payload) {
    payload.communityData = {
      communityName: "kotak treasury",
      uniqueId: localStorage.getItem("biri"),
    };

    const { data } = await Repository.post(
      `${resourcegetAllUserData}`,
      csencrypt.encrypt(JSON.stringify(payload))
    );
    return { data: csencrypt.decrypt(data) };
  },
  async editUserProfilePicture(payload) {
    payload.communityData = {
      communityName: "kotak treasury",
      uniqueId: localStorage.getItem("biri"),
    };
    const { data } = await Repository.post(
      `${resourceeditUserProfilePicture}`,
      csencrypt.encrypt(JSON.stringify(payload))
    );
    return { data: csencrypt.decrypt(data) };
  },
  async editUserProfileCoverPicture(payload) {
    payload.communityData = {
      communityName: "kotak treasury",
      uniqueId: localStorage.getItem("biri"),
    };
    const { data } = await Repository.post(
      `${resourceeditUserProfileCoverPicture}`,
      payload
    );
    return { data: csencrypt.decrypt(data) };
  },
  async resourceeditUserProfileCRN(payload) {
    payload.communityData = {
      communityName: "kotak treasury",
      uniqueId: localStorage.getItem("biri"),
    };
    const { data } = await Repository.post(
      `${resourceeditUserProfileCRN}`,
      csencrypt.encrypt(JSON.stringify(payload))
    );
    return { data: csencrypt.decrypt(data) };
  },
  async sendRequestRoleChange(payload) {
    payload.communityData = {
      communityName: "kotak treasury",
      uniqueId: localStorage.getItem("biri"),
    };
    const { data } = await Repository.post(
      `${resourceSendRequestRoleChange}`,
      csencrypt.encrypt(JSON.stringify(payload))
    );
    return { data: csencrypt.decrypt(data) };
  },

  async checkldap(payload) {
    payload.communityData = {
      communityName: "kotak treasury",
      uniqueId: localStorage.getItem("biri"),
    };
    const { data } = await Repository.post(
      `${resourceCheckldap}`,
      csencrypt.encrypt(JSON.stringify(payload))
    );
    return { data: csencrypt.decrypt(data) };
  },
  async acceptRequestRoleChange(payload) {
    payload.communityData = {
      communityName: "kotak treasury",
      uniqueId: localStorage.getItem("biri"),
    };
    const { data } = await Repository.post(
      `${resourceacceptRequestRoleChange}`,
      csencrypt.encrypt(JSON.stringify(payload))
    );
    console.log(csencrypt.decrypt(data));
    return { data: csencrypt.decrypt(data) };
  },
  async rejectRequestRoleChange(payload) {
    payload.communityData = {
      communityName: "kotak treasury",
      uniqueId: localStorage.getItem("biri"),
    };
    const { data } = await Repository.post(
      `${resourcerejectRequestRoleChange}`,
      csencrypt.encrypt(JSON.stringify(payload))
    );
    return { data: csencrypt.decrypt(data) };
  },
  async getPendingRequestRoleChange() {
    let payload = {
      communityData: {
        communityName: "Kotak Treasury",
        uniqueId: localStorage.getItem("biri"),
      },
    };
    console.log(payload);
    const { data } = await Repository.post(
      `${getPendingRequestRoleChange}`,
      csencrypt.encrypt(JSON.stringify(payload))
    );
    console.log(csencrypt.decrypt(data));
    return { data: csencrypt.decrypt(data) };
  },
};
