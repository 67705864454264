import { RepositoryFactory } from "@/Core/Apicall/RepositoryFactory";
const utilitiesRepository = RepositoryFactory.get("utility");

export default {
  addUtility({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await utilitiesRepository.addUtility(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  }
};
