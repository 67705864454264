import { ClientRepositoryFactory } from "@/Client/Kotak/kotakapicall/ClientRepositoryFactory";
const NoteRepository = ClientRepositoryFactory.get("note");

export default {
  addNote({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await NoteRepository.addNote(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  getNote({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await NoteRepository.getNote(payload);

        commit("SET_NOTES", data);

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  deleteNote({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await NoteRepository.deleteNote(payload);
        commit("REMOVE_NOTES", payload.id);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  updateNote({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await NoteRepository.updateNote(payload);
        commit("UPDATE_NOTE", payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  }
};
