import Repository from "@/Core/Apicall/Repository.js";

const resourceImportMoneyMarket = "Kotak/calendar/import_money_market";
const resourceGetMoneyMarket = "Kotak/calendar/get_money_market";
const resourceImportFX = "Kotak/calendar/import_fx";
const resourceGetFX = "Kotak/calendar/get_fx";
const resourceImportMarketLastYear = "Kotak/calendar/import_market_last_year";
const resourceImportInternationalData =
  "Kotak/calendar/import_international_data";
const resourceGetNotifications = "Kotak/calendar/get_event_notifications";
const resourceAddNotification = "Kotak/calendar/add_event_notification";
const resourceRemoveNotification = "Kotak/calendar/remove_event_notification";

export default {
  importMoneyMarket(payload) {
    return Repository.post(`${resourceImportMoneyMarket}`, payload);
  },
  getMoneyMarket(payload) {
    return Repository.post(`${resourceGetMoneyMarket}`, payload);
  },
  importFX(payload) {
    return Repository.post(`${resourceImportFX}`, payload);
  },
  getFX(payload) {
    return Repository.post(`${resourceGetFX}`, payload);
  },
  importMarketLastYear(payload) {
    return Repository.post(`${resourceImportMarketLastYear}`, payload);
  },
  importInternationalData(payload) {
    return Repository.post(`${resourceImportInternationalData}`, payload);
  },
  getNotifications(payload) {
    return Repository.post(`${resourceGetNotifications}`, payload);
  },
  addNotification(payload) {
    return Repository.post(`${resourceAddNotification}`, payload);
  },
  removeNotification(payload) {
    return Repository.post(`${resourceRemoveNotification}`, payload);
  }
};
