import PostsRepository from "./postsRepository";
import GitHubRepository from "./gitHubRepository";
import userRepository from "./userRepository";
import profileRepository from "./profileRepository";
import groupsRepository from "./groupsRepository";
import dashboardRepository from "./dashboardRepository";
import handleRepository from "./handleRepository";
import discussionRepository from "./discussionRepository";
import notificationsRepository from "./notificationsRepository";
import communityRepository from "./communityRepository";
import utilitiesRepository from "./utilitiesRepository";
import promotionsRepository from "./promotions.Repository";

const repositories = {
  posts: PostsRepository,
  gitHub: GitHubRepository,
  groups: groupsRepository,
  user: userRepository,
  profile: profileRepository,
  dashboard: dashboardRepository,
  handles: handleRepository,
  discussion: discussionRepository,
  notifications: notificationsRepository,
  community: communityRepository,
  utility: utilitiesRepository,
  promotions: promotionsRepository
  // other repositories ...
};

export const RepositoryFactory = {
  get: name => repositories[name]
};
