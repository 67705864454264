import { ClientRepositoryFactory } from "@/Client/Kotak/kotakapicall/ClientRepositoryFactory";
const shortURLRepository = ClientRepositoryFactory.get("shortURL");

export default {
  addShortUrl({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await shortURLRepository.addShortURL(payload);
        console.log(data);
        commit("setURL", data[0]);
        resolve(data[0]);
      } catch (error) {
        reject(error);
      }
    });
  },
  getFullUrl({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await shortURLRepository.getFullURL(payload);
        commit("setURL", data[0]);
        resolve(data[0]);
      } catch (error) {
        reject(error);
      }
    });
  },
  getLastURL({}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await shortURLRepository.getLastURL(payload);
        resolve(data);
      } catch (error) {
        if (error) reject(error);
      }
    });
  }
};
