export default {
  addIP(state, payload) {
    state.whiteListedIPs.push(payload);
  },
  updateData(state, payload) {
    const index = state.whiteListedIPs.findIndex(x => x._id === payload.id);
    state.whiteListedIPs[index][payload.key] = payload.value;
  },
  setData(state, payload) {
    if (payload.length === 0) return;
    if (state.whiteListedIPs.length === 0) {
      state.whiteListedIPs = payload;
    } else {
      state.whiteListedIPs = [...state.whiteListedIPs, payload];
    }
  },
  deleteIP(state, payload) {
    const index = state.whiteListedIPs.findIndex(x => x._id === payload.id);
    state.whiteListedIPs.splice(index, 1);
  },
  resetData(state) {
    state.whiteListedIPs = [];
  }
};
