/*=========================================================================================
  File Name: globalComponents.js
  Description: Here you can register components globally
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from "vue";

import VxCard from "./Core/components/vx-card/VxCard.vue";
import VxList from "./Core/components/vx-list/VxList.vue";

import FeatherIcon from "./Core/components/FeatherIcon.vue";
import VxInputGroup from "./Core/components/vx-input-group/VxInputGroup.vue";
import VueContentLoading from "vue-content-loading";

Vue.component(VxCard.name, VxCard);
Vue.component(VxList.name, VxList);

Vue.component(FeatherIcon.name, FeatherIcon);
Vue.component(VxInputGroup.name, VxInputGroup);
Vue.component(VueContentLoading.name, VueContentLoading);

// v-select component
import vSelect from "vue-select";

// Set the components prop default to return our fresh components
vSelect.props.components.default = () => ({
  Deselect: {
    render: createElement =>
      createElement("feather-icon", {
        props: {
          icon: "XIcon",
          svgClasses: "w-4 h-4 mt-1"
        }
      })
  },
  OpenIndicator: {
    render: createElement =>
      createElement("feather-icon", {
        props: {
          icon: "ChevronDownIcon",
          svgClasses: "w-5 h-5"
        }
      })
  }
});

Vue.component(vSelect);
