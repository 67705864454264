/*=========================================================================================
  File Name: moduleChatMutations.js
  Description: Chat Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  UPDATE_RECOMMENDEDGROUPS(state, chats) {
    state.recommendedgroupArray = chats;
  },
  updateCurrentGroupID(state, ID) {
    state.groupId = ID;
  },
  channelMember(state, payload) {
    state.channelMember = payload;
  },
};
