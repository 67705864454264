export default {
  async selectUser({ commit }, payload) {
    commit("addUser", payload);
  },
  async unselectUser({ commit }, payload) {
    commit("removeUser", payload);
  },
  async selectAllUsers({ commit }) {
    commit("selectAll");
  },
  async unselectAllUsers({ commit }) {
    commit("unselectAll");
  },
  async resetUsers({ commit }) {
    commit("resetUsers");
  },
  async setUsers({ commit }, payload) {
    commit("setUsers", payload);
  }
};
