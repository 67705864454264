/*=========================================================================================
  File Name: store.js
  Description: Vuex store
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from "vue";
import Vuex from "vuex";

import state from "./state";
import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";

Vue.use(Vuex);

// import moduleTodo from './todo/moduleTodo.js'
// import moduleCalendar from './calendar/moduleCalendar.js'
// import moduleChat from "./chat/moduleChat.js";
import moduleGroup from "./Groups/moduleGroup.js";
// import moduleAuth from "./auth/moduleAuth.js";
// import moduleECommerce from "./eCommerce/moduleECommerce.js";
import moduleShortURL from "@/Client/Kotak/store/short-url/moduleShortURL.js";
import modulePost from "./Posts/modulePost";
import moduleUserInvitation from "@/Client/Kotak/store/user-invitation/moduleUserInvitation.js";
import moduleUser from "./User/moduleUser";
import moduleWhitelist from "@/Client/Kotak/store/whitelist/moduleWhitelist.js";
import moduleCalendar from "@/Client/Kotak/store/economic-calendar/moduleEconomicCalendar.js";
import moduleNote from "@/Client/Kotak/store/note/moduleNote.js";
import moduleUtilities from "@/Client/Kotak/store/utilities/moduleUtilities";
//alert("in");
export default new Vuex.Store({
  getters,
  mutations,
  state,
  actions,
  modules: {
    // todo: moduleTodo,
    calendar: moduleCalendar,
    // chat: moduleChat,
    Group: moduleGroup,
    shortURL: moduleShortURL,
    post: modulePost,
    userInvitation: moduleUserInvitation,
    user: moduleUser,
    whitelist: moduleWhitelist,
    note: moduleNote,
    utilities: moduleUtilities
    // auth: moduleAuth,
    // eCommerce: moduleECommerce
  },
  strict: process.env.NODE_ENV !== "production"
});
