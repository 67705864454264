/*=========================================================================================
  File Name: moduleUserManagement.js
  Description: Calendar Module
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import state from "./moduleWhitelistState.js";
import mutations from "./moduleWhitelistMutations.js";
import actions from "./moduleWhitelistActions.js";
import getters from "./moduleWhitelistnGetters.js";

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
