export default {
  eventLabels: [
    {
      color: "calendar-amber",
      text: "High",
      value: "Hign",
    },
    {
      color: "calendar-green",
      text: "Medium",
      value: "Medium",
    },
    {
      color: "calendar-purple",
      text: "Low",
      value: "Low",
    },
  ],
  fxEventLabels: [
    {
      color: "calendar-blue",
      text: "IN = India",
      value: "Hign",
    },
    {
      color: "calendar-blue",
      text: "BLR = Bengaluru",
      value: "Medium",
    },
    {
      color: "calendar-blue",
      text: "CHN = Chennai",
      value: "Low",
    },
    {
      color: "calendar-blue",
      text: "DEL = Delhi",
      value: "Hign",
    },
    {
      color: "calendar-blue",
      text: "KOL = Kolkata",
      value: "Medium",
    },
    {
      color: "calendar-blue",
      text: "MUM = Mumbai",
      value: "Low",
    },
    {
      color: "calendar-blue",
      text: "AUS = Australia",
      value: "Hign",
    },
    {
      color: "calendar-blue",
      text: "CH = China",
      value: "Medium",
    },
    {
      color: "calendar-blue",
      text: "EZ = Euro",
      value: "Low",
    },
    {
      color: "calendar-blue",
      text: "GER = Germany",
      value: "Hign",
    },
    {
      color: "calendar-blue",
      text: "HK = Hong Kong",
      value: "Medium",
    },
    {
      color: "calendar-blue",
      text: "JP = Tokyo",
      value: "Low",
    },
    {
      color: "calendar-blue",
      text: "SG = Singapore",
      value: "Hign",
    },
    {
      color: "calendar-blue",
      text: "UK = London",
      value: "Medium",
    },
    {
      color: "calendar-blue",
      text: "US = New York ",
      value: "Low",
    },
  ],
  mmEventLabels: [
    {
      color: "calendar-blue",
      text: "IN-India",
    },
    {
      color: "calendar-blue",
      text: "US-United States",
    },
    {
      color: "calendar-blue",
      text: "EZ-Eurozone",
    },
    {
      color: "calendar-blue",
      text: "JP-Japan",
    },
    {
      color: "calendar-blue",
      text: "UK-United Kingdom",
    },
    {
      color: "calendar-blue",
      text: "G-Sec – Central Government Security",
    },
    {
      color: "calendar-blue",
      text: "FRB – Floating Rate Bond",
    },
    {
      color: "calendar-blue",
      text: "SDL – State Development Loan",
    },
    {
      color: "calendar-blue",
      text: "UDAY – Ujjwal DISCOM Assurance Yojana",
    },
    {
      color: "calendar-blue",
      text: "FCI – Food Corporation of India",
    },
    {
      color: "calendar-blue",
      text: "MPC – Monetary Policy Committee",
    },
    {
      color: "calendar-blue",
      text: "FOMC – Federal Open Market Committee",
    },
    {
      color: "calendar-blue",
      text: "ECB – European Central Bank",
    },
    {
      color: "calendar-blue",
      text: "BoJ – Bank of Japan",
    },
    {
      color: "calendar-blue",
      text: "BoE – Bank of England",
    },
  ],
  otherFXEventLabels: [
    {
      color: "calendar-blue",
      text: "Data Releases in Blue",
      value: "Low",
    },
    {
      color: "primary",
      text: "Holidays & Central Bank Announcements in Red",
      value: "Medium",
    },
  ],
  otherMMEventLabels: [
    {
      color: "primary",
      text: "Holidays & Central Bank Announcements in Red",
      value: "Medium",
    },
  ],
  events: [],
  futureEvents: [],
  moneyMarketEvents: [],
  fxEvents: [],
  lastWorkingDays: [
    {
      month: "January 2022",
      day: "31-Jan",
    },
    {
      month: "February 2022",
      day: "28-Feb",
    },
    {
      month: "March 2022",
      day: "31 - Mar",
    },
    {
      month: "April 2022",
      day: "30-Apr",
    },
    {
      month: "May 2022",
      day: "28-May",
    },
    {
      month: "June 2022",
      day: "30-Jun",
    },
    {
      month: "July 2022",
      day: "30-Jul",
    },
    {
      month: "August 2022",
      day: "31-Aug",
    },
    {
      month: "September 2022",
      day: "30-Sep",
    },
    {
      month: "October 2022",
      day: "29-Oct",
    },
    {
      month: "November 2022",
      day: "30-Nov",
    },
    {
      month: "December 2022",
      day: "31-Dec",
    },
    {
      month: "January 2023",
      day: "31-Jan",
    },
    {
      month: "February 2023",
      day: "28-Feb",
    },
    {
      month: "March 2023",
      day: "31 - Mar",
    },
    {
      month: "April 2023",
      day: "29-Apr",
    },
    {
      month: "May 2023",
      day: "31-May",
    },
    {
      month: "June 2023",
      day: "30-Jun",
    },
    {
      month: "July 2023",
      day: "29-Jul",
    },
    {
      month: "August 2023",
      day: "30-Aug",
    },
    {
      month: "September 2023",
      day: "30-Sep",
    },
    {
      month: "October 2023",
      day: "31-Oct",
    },
    {
      month: "November 2023",
      day: "30-Nov",
    },
    {
      month: "December 2023",
      day: "30-Dec",
    },
  ],
  eventNotifications: [],
  actualNotifications: [],
};
