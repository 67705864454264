/*=========================================================================================
  File Name: moduleChatActions.js
  Description: Chat Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
import { RepositoryFactory } from "@/Core/Apicall/RepositoryFactory";
const userRepository = RepositoryFactory.get("user");

export default {
  storeUserToken({ commit }, token) {
    commit("UPDATE_USERTOKEN", token);
  },
  storeUserImage({ commit }, image) {
    commit("updateUserImage", image);
  },
  async checkWhitelisted({}) {
    const { data } = await userRepository.checkWhitelisted();
    return { data };
  }
};
