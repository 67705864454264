// import Repository from "@/Core/Apicall/Repository.js";
import SecureRepository from "@/Core/Apicall/SecureRepository";
import csencrypt from "@/Core/config/csencrypt";

const resourceSendPlainEmail = "kotakEmailSMSApi/mwfsendmail";
const resourceSendPlainSMS = "kotakEmailSMSApi/mwfsendsms";
const resourceSendBroadCastSMS = "kotakEmailSMSApi/mwfsendbroadcastsms";
const resourceOptWA = "kotakEmailSMSApi/mwfoptwa";
const resourceSendPushWA = "kotakEmailSMSApi/mwfsendpushwa";

export default {
  async sendPlainEmail(payload) {
    payload.communityData = { uniqueId: localStorage.getItem("biri") };
    let { data } = await SecureRepository.post(
      `${resourceSendPlainEmail}`,
      csencrypt.encrypt(JSON.stringify(payload))
    );
    return { data: csencrypt.decrypt(data) };
  },
  async sendPlainSMS(payload) {
    payload.communityData = { uniqueId: localStorage.getItem("biri") };
    let { data } = await SecureRepository.post(
      `${resourceSendPlainSMS}`,
      csencrypt.encrypt(JSON.stringify(payload))
    );
    return { data: csencrypt.decrypt(data) };
  },
  async sendBroadcastSMS(payload) {
    payload.communityData = { uniqueId: localStorage.getItem("biri") };
    let { data } = await SecureRepository.post(
      `${resourceSendBroadCastSMS}`,
      csencrypt.encrypt(JSON.stringify(payload))
    );
    return { data: csencrypt.decrypt(data) };
    // return Repository.post(`${resourceSendBroadCastSMS}`, payload);
  },
  async optwa(payload) {
    payload.communityData = { uniqueId: localStorage.getItem("biri") };
    let { data } = await SecureRepository.post(
      `${resourceOptWA}`,
      csencrypt.encrypt(JSON.stringify(payload))
    );
    return { data: csencrypt.decrypt(data) };
    // return Repository.post(`${resourceSendBroadCastSMS}`, payload);
  },
  async sendPushWA(payload) {
    console.log(payload,"jhj");
    payload.communityData = { uniqueId: localStorage.getItem("biri") };
    let { data } = await SecureRepository.post(
      `${resourceSendPushWA}`,
      csencrypt.encrypt(JSON.stringify(payload))
    );
    return { data: csencrypt.decrypt(data) };
    // return Repository.post(`${resourceSendBroadCastSMS}`, payload);
  }
};
