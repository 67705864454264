import Repository from "./Repository";
import SecureRepository from "./SecureRepository";
import csencrypt from "../config/csencrypt";

// const resource = "secureApi/getContactList";
const resourceContactList = "secureApi/getContactList";
const resourcegetUser = "secureApi/getUser";
const resourceCommunityUserSignup = "secureApi/CommunityUserSignup";
const resourcecheckPhoneNumber = "secureApi/getPhoneNumber";
const resourceCheckPhoneNumberForRegister =
  "secureApi/getPhoneNumberForMainRegister";
const resourceCheckPhoneandCommunityNumberForRegister =
  "secureApi/getPhoneNumberandCommunityForMainRegister";
const resourcecheckchangePassword = "secureApi/changePassword";
const resourcecheckchangePasswordForMaster =
  "secureApi/changePasswordForMaster";

const resourcegetAllUsers = "secureApi/getAllUsers";
const resourcesearchAllUsers = "secureApi/searchAllUsers";
const resourcegetCommunitiesOfUser = "secureApi/getCommunitiesOfUser";
const resourceNewUserSignup = "secureApi/NewUserSignup";
const resourceMainLogin = "secureApi/mainLogin";
const resourcegetUserOfCommunity = "secureApi/getUserOfCommunity";
const resourcejoinCommunity = "secureApi/joinCommunity";
const UpdateCoonectyCubeId = "secureApi/UpdateConnectyCubeId";
const resourceUpdateUserOnBoardingStatus =
  "secureApi/updateUserOnBoardingStatus";
const resourceAddExcelInvites = "secureApi/addExcelInvites";
const resourceCheckInvitationStatus = "secureApi/checkInvitationStatus";
const resourceGetCommunityManagers = "secureApi/getCommunityManagers";
const resourceGetCommunityOwners = "secureApi/getCommunityOwners";
const resourceChangeUserStatus = "secureApi/changeUserStatus";
const resourceChangeUserBlockStatus = "secureApi/changeUserBlockStatus";
const resourceGetNormalUser = "secureApi/getNormalUser";
const resourceAddNormalUser = "secureApi/addNormalUser";
const resourceMakeCommunityManager = "secureApi/addCommunityManager";
const resourceRemoveCommunityManager = "secureApi/removeCommunityManager";
const resourceSendLinkForEmailVarification = "SendMailApi/newsendmail";
const resourceSendOTP = "secureApi/sendOTP";
const resourceSendOTPInMaster = "secureApi/sendOTPInMaster";
const resourceAddUserProfileData = "secureApi/addUserProfileData";
const resourceCheckOTP = "secureApi/checkOTP";
const resourceCheckOTPInMaster = "secureApi/checkOTPInMaster";
const resourceGetExcelInvitedUsers = "secureApi/getExcelInvitedUsers";
const resourceCreateReader = "secureApi/createReader";
const resourceDeleteExcelInvitedUser = "secureApi/deleteExcelInvitedUser";
const resourceGetUsersByMobileNumber = "secureApi/getUsersByMobileNumber";
const resourceCheckWhitelisted = "secureApi/checkWhitelisted";

export default {
  // get() {
  //   return Repository.get(`${resource}`);
  // },

  // getPost(postId) {
  //   return Repository.get(`${resource}/${postId}`);
  // },

  async getContactList(payload) {
    payload.communityData.uniqueId = localStorage.getItem("biri");
    let { data } = await SecureRepository.post(
      `${resourceContactList}`,
      csencrypt.encrypt(JSON.stringify(payload))
    );
    return { data: csencrypt.decrypt(data) };
  },

  getUser(payload) {
    return Repository.post(`${resourcegetUser}`, payload);
  },
  getUserOfCommunity(payload) {
    return Repository.post(`${resourcegetUserOfCommunity}`, payload);
  },
  mainLogin(payload) {
    return Repository.post(`${resourceMainLogin}`, payload);
  },
  signup(payload) {
    return Repository.post(`${resourceCommunityUserSignup}`, payload);
  },
  mainSignup(payload) {
    return Repository.post(`${resourceNewUserSignup}`, payload);
  },
  checkPhoneNumber(payload) {
    return Repository.post(`${resourcecheckPhoneNumber}`, payload);
  },
  checkPhoneNumberForRegister(payload) {
    return Repository.post(`${resourceCheckPhoneNumberForRegister}`, payload);
  },
  checkPhoneNumberAndCommunityForRegister(payload) {
    return Repository.post(
      `${resourceCheckPhoneandCommunityNumberForRegister}`,
      payload
    );
  },
  changePassword(payload) {
    return Repository.post(`${resourcecheckchangePassword}`, payload);
  },
  changePasswordForMaster(payload) {
    return Repository.post(`${resourcecheckchangePasswordForMaster}`, payload);
  },
  async getAllUsers(payload) {
    payload.communityData.uniqueId = localStorage.getItem("biri");
    let { data } = await SecureRepository.post(
      `${resourcegetAllUsers}`,
      csencrypt.encrypt(JSON.stringify(payload))
    );
    return { data: csencrypt.decrypt(data) };
  },
  searchAllUsers(payload) {
    return SecureRepository.post(`${resourcesearchAllUsers}`, payload);
  },
  getCommunitiesOfUser(payload) {
    return Repository.post(`${resourcegetCommunitiesOfUser}`, payload);
  },
  joinCommunity(payload) {
    return Repository.post(`${resourcejoinCommunity}`, payload);
  },
  UpdateCoonectyCubeId(payload) {
    return Repository.post(`${UpdateCoonectyCubeId}`, payload);
  },
  async updateUserOnBoardingStatus(payload) {
    payload.communityData.uniqueId = localStorage.getItem("biri");
    let { data } = await SecureRepository.post(
      `${resourceUpdateUserOnBoardingStatus}`,
      csencrypt.encrypt(JSON.stringify(payload))
    );
    return { data: csencrypt.decrypt(data) };
  },
  async addExcelInvites(payload) {
    payload.communityData.uniqueId = localStorage.getItem("biri");
    let { data } = await SecureRepository.post(
      `${resourceAddExcelInvites}`,
      csencrypt.encrypt(JSON.stringify(payload))
    );
    return { data: csencrypt.decrypt(data) };
  },
  checkInvitationStatus(payload) {
    return Repository.post(`${resourceCheckInvitationStatus}`, payload);
  },
  getCommunityManagers(payload) {
    return Repository.post(`${resourceGetCommunityManagers}`, payload);
  },
  getCommunityOwners(payload) {
    return Repository.post(`${resourceGetCommunityOwners}`, payload);
  },
  async changeUserStatus(payload) {
    payload.communityData.uniqueId = localStorage.getItem("biri");
    let { data } = await SecureRepository.post(
      `${resourceChangeUserStatus}`,
      csencrypt.encrypt(JSON.stringify(payload))
    );
    return { data: csencrypt.decrypt(data) };
  },
  async changeUserBlockStatus(payload) {
    payload.communityData.uniqueId = localStorage.getItem("biri");
    let { data } = await SecureRepository.post(
      `${resourceChangeUserBlockStatus}`,
      csencrypt.encrypt(JSON.stringify(payload))
    );
    return { data: csencrypt.decrypt(data) };
  },
  async getNormalUser(payload) {
    // return Repository.post(`${resourceGetNormalUser}`, payload);
    payload.uniqueId = localStorage.getItem("biri");
    let { data } = await SecureRepository.post(
      `${resourceGetNormalUser}`,
      csencrypt.encrypt(JSON.stringify(payload))
    );
    return { data: csencrypt.decrypt(data) };
  },
  addNormalUser(payload) {
    return Repository.post(`${resourceAddNormalUser}`, payload);
  },
  async makeCommunityManager(payload) {
    payload.communityData.uniqueId = localStorage.getItem("biri");
    let { data } = await SecureRepository.post(
      `${resourceMakeCommunityManager}`,
      csencrypt.encrypt(JSON.stringify(payload))
    );
    return { data: csencrypt.decrypt(data) };
  },
  async removeCommunityManager(payload) {
    payload.communityData.uniqueId = localStorage.getItem("biri");
    let { data } = await SecureRepository.post(
      `${resourceRemoveCommunityManager}`,
      csencrypt.encrypt(JSON.stringify(payload))
    );
    return { data: csencrypt.decrypt(data) };
  },
  SendLinkForEmailVarification(payload) {
    return Repository.post(`${resourceSendLinkForEmailVarification}`, payload);
  },
  sendOTP(payload) {
    return Repository.post(`${resourceSendOTP}`, payload);
  },
  addUserProfileData(payload) {
    return Repository.post(`${resourceAddUserProfileData}`, payload);
  },
  checkOTP(payload) {
    return Repository.post(`${resourceCheckOTP}`, payload);
  },
  sendOTPInMaster(payload) {
    return Repository.post(`${resourceSendOTPInMaster}`, payload);
  },
  checkOTPInMaster(payload) {
    return Repository.post(`${resourceCheckOTPInMaster}`, payload);
  },
  async getExcelInvitedUsers(payload) {
    payload.communityData.uniqueId = localStorage.getItem("biri");
    let { data } = await SecureRepository.post(
      `${resourceGetExcelInvitedUsers}`,
      csencrypt.encrypt(JSON.stringify(payload))
    );
    return { data: csencrypt.decrypt(data) };
  },
  createReader(payload) {
    return Repository.post(`${resourceCreateReader}`, payload);
  },
  async deleteExcelInvitedUser(payload) {
    payload.communityData.uniqueId = localStorage.getItem("biri");
    let { data } = await SecureRepository.post(
      `${resourceDeleteExcelInvitedUser}`,
      csencrypt.encrypt(JSON.stringify(payload))
    );
    return { data: csencrypt.decrypt(data) };
  },
  async getUsersByMobileNumber(payload) {
    payload.communityData.uniqueId = localStorage.getItem("biri");
    let { data } = await SecureRepository.post(
      `${resourceGetUsersByMobileNumber}`,
      csencrypt.encrypt(JSON.stringify(payload))
    );
    return { data: csencrypt.decrypt(data) };
  },
  async checkWhitelisted() {
    return Repository.get(`${resourceCheckWhitelisted}`);
  },
};
