import state from "./moduleEconomicCalendarState.js";
import mutations from "./moduleEconomicCalendarMutations.js";
import actions from "./moduleEconomicCalendarActions.js";
import getters from "./moduleEconomicCalendarGetters.js";

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
