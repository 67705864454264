import Repository from "./SecureRepository";

// const resource = "secureApi/getContactList";
const resourceAddGeneralDiscussion =
  "BrainstormDiscussionApi/addGeneralDiscussion";
const resourceGetGeneralDiscussion =
  "BrainstormDiscussionApi/getGeneralDiscussion";
const resourceGetAllGeneralDiscussion =
  "BrainstormDiscussionApi/getAllGeneralDiscussion";
const resourceAddUsersToDiscussion =
  "BrainstormDiscussionApi/addUsersToDiscussion";
const resourceGetUsersOfDiscussion =
  "BrainstormDiscussionApi/getUsersOfDiscussion";
const resourceGetGeneralDiscussionsOfGroup =
  "BrainstormDiscussionApi/getGeneralDiscussionsOfGroup";
const resourceAddGeneralDiscussionStickyNotes =
  "BrainstormDiscussionApi/addGeneralDiscussionStickyNotes";
const resourceRemoveGeneralDiscussionStickyNotes =
  "BrainstormDiscussionApi/removeGeneralDiscussionStickyNotes";
const resourceGetGeneralDiscussionStickyNotes =
  "BrainstormDiscussionApi/getGeneralDiscussionStickyNotes";
const resourceLikeGeneralDiscussionStickyNote =
  "BrainstormDiscussionApi/likeGeneralDiscussionStickyNote";
const resourceEditLikedGeneralDiscussionStickyNote =
  "BrainstormDiscussionApi/editLikedGeneralDiscussionStickyNote";
const resourceGetLikesOfGeneralDiscussionStickyNote =
  "BrainstormDiscussionApi/getLikesOfGeneralDiscussionStickyNote";
const resourceInsertCommentInGeneralDiscussion =
  "BrainstormDiscussionApi/insertCommentInGeneralDiscussion";
const resourceGetCommentsInGeneralDiscussion =
  "BrainstormDiscussionApi/getCommentsInGeneralDiscussion";

export default {
  addGeneralDiscussion(payload) {
    return Repository.post(`${resourceAddGeneralDiscussion}`, payload);
  },

  getGeneralDiscussion(payload) {
    return Repository.post(`${resourceGetGeneralDiscussion}`, payload);
  },

  getAllGeneralDiscussion(payload) {
    return Repository.post(`${resourceGetAllGeneralDiscussion}`, payload);
  },

  addUsersToDiscussion(payload) {
    return Repository.post(`${resourceAddUsersToDiscussion}`, payload);
  },

  getUsersOfDiscussion(payload) {
    return Repository.post(`${resourceGetUsersOfDiscussion}`, payload);
  },

  getGeneralDiscussionsOfGroup(payload) {
    return Repository.post(`${resourceGetGeneralDiscussionsOfGroup}`, payload);
  },

  addGeneralDiscussionStickyNotes(payload) {
    return Repository.post(
      `${resourceAddGeneralDiscussionStickyNotes}`,
      payload
    );
  },

  removeGeneralDiscussionStickyNotes(payload) {
    return Repository.post(
      `${resourceRemoveGeneralDiscussionStickyNotes}`,
      payload
    );
  },

  getGeneralDiscussionStickyNotes(payload) {
    return Repository.post(
      `${resourceGetGeneralDiscussionStickyNotes}`,
      payload
    );
  },

  likeGeneralDiscussionStickyNote(payload) {
    return Repository.post(
      `${resourceLikeGeneralDiscussionStickyNote}`,
      payload
    );
  },

  editLikedGeneralDiscussionStickyNote(payload) {
    return Repository.post(
      `${resourceEditLikedGeneralDiscussionStickyNote}`,
      payload
    );
  },

  getLikesOfGeneralDiscussionStickyNote(payload) {
    return Repository.post(
      `${resourceGetLikesOfGeneralDiscussionStickyNote}`,
      payload
    );
  },

  insertCommentInGeneralDiscussion(payload) {
    return Repository.post(
      `${resourceInsertCommentInGeneralDiscussion}`,
      payload
    );
  },

  getCommentsInGeneralDiscussion(payload) {
    return Repository.post(
      `${resourceGetCommentsInGeneralDiscussion}`,
      payload
    );
  }
};
