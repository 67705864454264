import Repository from "./SecureRepository";

const resourceAddSentPromotion = "BrainstormPromotionsApi/addSentPromotion";
const resourceAddViewedPromotion = "BrainstormPromotionsApi/addViewedPromotion";
const resourceUpdateViewedPromotion =
  "BrainstormPromotionsApi/updateViewedPromotion";
const resourceAddSharedPromotion = "BrainstormPromotionsApi/addSharedPromotion";
const resourceGetReceipientWiseData =
  "BrainstormPromotionsApi/getReceipientWiseData";
const resourceGetArticleWiseData = "BrainstormPromotionsApi/getArticleWiseData";
const resourceGetTimeRangeData = "BrainstormPromotionsApi/getTimeRangeData";
const resourceGetStaticInfoData = "BrainstormPromotionsApi/getStaticInfoData";
const resourceGetInvitedUsers = "BrainstormPromotionsApi/getInvitedUsers";

export default {
  addSentPromotion(payload) {
    return Repository.post(`${resourceAddSentPromotion}`, payload);
  },
  addViewedPromotion(payload) {
    return Repository.post(`${resourceAddViewedPromotion}`, payload);
  },
  updateViewedPromotion(payload) {
    return Repository.post(`${resourceUpdateViewedPromotion}`, payload);
  },
  addSharedPromotion(payload) {
    return Repository.post(`${resourceAddSharedPromotion}`, payload);
  },
  getReceipientWiseData(payload) {
    return Repository.post(`${resourceGetReceipientWiseData}`, payload);
  },
  getArticleWiseData(payload) {
    return Repository.post(`${resourceGetArticleWiseData}`, payload);
  },
  getTimeRangeData(payload) {
    return Repository.post(`${resourceGetTimeRangeData}`, payload);
  },
  getStaticinfoData(payload) {
    return Repository.post(`${resourceGetStaticInfoData}`, payload);
  },
  getInvitedUsers(payload) {
    return Repository.post(`${resourceGetInvitedUsers}`, payload);
  },
};
