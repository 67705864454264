import Repository from "./Repository";

const resourcegetAllUtilities = "BrainstormUtilitiesApi/getAllUtilities";
const resourceaddUtility = "BrainstormUtilitiesApi/addUtility";

export default {
  getAllUtilities(payload) {
    return Repository.post(`${resourcegetAllUtilities}`, payload);
  },
  addUtility(payload) {
    return Repository.post(`${resourceaddUtility}`, payload);
  }
};
