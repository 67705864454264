import Repository from "@/Core/Apicall/Repository.js";
import csencrypt from "@/Core/config/csencrypt";

const resourceAuthenticateLDAP = "ldap/auth";
const resourceMapLDAP = "ldap/mapLDAP";
const resourceLogout = "ldap/logout";

export default {
  async authenticate(payload) {
    let { data } = await Repository.post(
      `${resourceAuthenticateLDAP}`,
      csencrypt.encrypt(JSON.stringify(payload))
    );
    return { data: csencrypt.decrypt(data) };
    // return Repository.post(`${resourceAuthenticateLDAP}`, payload);
  },
  mapLDAP(payload) {
    return Repository.post(`${resourceMapLDAP}`, payload);
  },
  logout(payload) {
    return Repository.post(`${resourceLogout}`, payload);
  },
};
