export default {
  SET_NOTES(state, payload) {
    if (!state.notes.length) {
      state.notes = payload;
    } else {
      state.notes = [...state.notes, ...payload];
    }
  },

  REMOVE_NOTES(state, payload) {
    if (!payload) return;
    const index = state.notes.findIndex(x => x._id === payload);
    if (index === -1) return;
    state.notes.splice(index, 1);
  },

  UPDATE_NOTE(state, payload) {
    if (!payload.id) return;
    const index = state.notes.findIndex(x => x._id === payload.id);

    if (index === -1) return;
    state.notes[index].note = payload.note;
  }
};
