import Repository from "./SecureRepository";
import csencrypt from "../config/csencrypt";
//const resourcegetUserProfileData = "BrainstormUserSettingsApi/getUserProfile";
const getPostsOfGroup = "BrainstormPostsApi/getPostsOfGroup";
const getPendingGroups = "BrainstormGroupsApi/getPendingGroups";
const editGroupStatus = "BrainstormGroupsApi/editGroupStatus";
const makeGroupAdmin = "BrainstormGroupsApi/makeGroupAdmin";
const removeGroupAdmin = "BrainstormGroupsApi/removeGroupAdmin";
const leaveGroup = "BrainstormGroupsApi/exitGroup";
const getGroup = "BrainstormGroupsApi/getGroup";
const getFilteredGroupsOfUser = "BrainstormGroupsApi/getFilteredGroupsOfUser";
const getAllGroups = "BrainstormGroupsApi/getAllGroups";
const searchAllGroups = "BrainstormGroupsApi/searchAllGroups";
const addGroup = "BrainstormGroupsApi/addGroup";
const inviteUserToGroup = "BrainstormGroupsApi/inviteUserToGroup";
const getUsersInGroup = "BrainstormGroupsApi/getUsersInGroup";
const getUsersInPublicGroup = "BrainstormGroupsApi/getUsersInPublicGroup";
const editGroup = "BrainstormGroupsApi/editGroup";
const getJoinRequestsOfGroup = "BrainstormGroupsApi/getJoinRequestsOfGroup";
const invitationResponseOfGroup =
  "BrainstormGroupsApi/invitationResponseOfGroup";
const invitationResponseOfUser = "BrainstormGroupsApi/invitationResponseOfUser";
const getGroupsOfUserWithPostingRights =
  "BrainstormGroupsApi/getGroupsOfUserWithPostingRights";
const getUniverseGroup = "BrainstormGroupsApi/getUniverseGroup";
const requestToJoinGroup = "BrainstormGroupsApi/requestToJoinGroup";
const getGroupsOfUser = "BrainstormGroupsApi/getGroupsOfUser";
const getPublicGroups = "BrainstormGroupsApi/getPublicGroups";
const getPrivateGroups = "BrainstormGroupsApi/getPrivateGroups";
const getSystemGroups = "BrainstormGroupsApi/getSystemGroups";
const getRecommendedGroups = "BrainstormGroupsApi/getRecommendedGroups";
const getMembersForGroupChat = "BrainstormGroupsApi/getMembersForGroupChat";
const insertAllMembersInGroup = "BrainstormGroupsApi/insertAllMembersInGroup";
const changePostingRights = "BrainstormGroupsApi/changePostingRights";
const changeGroupStatus = "BrainstormGroupsApi/editGroupStatus";
const MakeGroupOwnerAndLeave = "BrainstormGroupsApi/MakeGroupOwnerAndLeave";
const getGroupByName = "BrainstormGroupsApi/getGroupByName";
const deleteGroup = "BrainstormGroupsApi/deleteGroup";
const getUsersFromGroupIDs = "BrainstormGroupsApi/getUsersFromGroupIDs";
const exportUsersInGroup = "BrainstormGroupsApi/exportUsersInGroup";
const getCommunityOwner = "BrainstormGroupsApi/getCommunityOwner";
const getCommunityGroup = "BrainstormGroupsApi/getCommunityGroup";

export default {
  async MakeGroupOwnerAndLeave(payload) {
    payload.communityData.uniqueId = localStorage.getItem("biri");
    let { data } = await Repository.post(
      `${MakeGroupOwnerAndLeave}`,
      csencrypt.encrypt(JSON.stringify(payload))
    );
    return { data: csencrypt.decrypt(data) };
  },
  // getPostsOfGroup(payload) {
  //     return Repository.post(`${resourcegetUserProfileData}`, payload);
  //   },
  async getPostsOfGroup(payload) {
    payload.communityData.uniqueId = localStorage.getItem("biri");
    let { data } = await Repository.post(
      `${getPostsOfGroup}`,
      csencrypt.encrypt(JSON.stringify(payload))
    );
    return { data: csencrypt.decrypt(data) };
  },
  async getPendingGroups(payload) {
    payload.communityData.uniqueId = localStorage.getItem("biri");
    let { data } = await Repository.post(
      `${getPendingGroups}`,
      csencrypt.encrypt(JSON.stringify(payload))
    );
    return { data: csencrypt.decrypt(data) };
  },
  async editGroupStatus(payload) {
    payload.communityData.uniqueId = localStorage.getItem("biri");
    let { data } = await Repository.post(
      `${editGroupStatus}`,
      csencrypt.encrypt(JSON.stringify(payload))
    );
    return { data: csencrypt.decrypt(data) };
  },
  async makeGroupAdmin(payload) {
    payload.communityData.uniqueId = localStorage.getItem("biri");
    let { data } = await Repository.post(
      `${makeGroupAdmin}`,
      csencrypt.encrypt(JSON.stringify(payload))
    );
    return { data: csencrypt.decrypt(data) };
  },
  async removeGroupAdmin(payload) {
    payload.communityData.uniqueId = localStorage.getItem("biri");
    let { data } = await Repository.post(
      `${removeGroupAdmin}`,
      csencrypt.encrypt(JSON.stringify(payload))
    );
    return { data: csencrypt.decrypt(data) };
  },

  async leaveGroup(payload) {
    payload.communityData.uniqueId = localStorage.getItem("biri");
    let { data } = await Repository.post(
      `${leaveGroup}`,
      csencrypt.encrypt(JSON.stringify(payload))
    );
    return { data: csencrypt.decrypt(data) };
  },
  async getGroup(payload) {
    payload.communityData.uniqueId = localStorage.getItem("biri");
    let { data } = await Repository.post(
      `${getGroup}`,
      csencrypt.encrypt(JSON.stringify(payload))
    );
    if (data.status === 500) {
      // alert("Your session expired");
      // localStorage.removeItem('token');
      // location.reload();
      // return false
    }
    return { data: csencrypt.decrypt(data) };
  },
  async getFilteredGroupsOfUser(payload) {
    payload.communityData.uniqueId = localStorage.getItem("biri");
    let { data } = await Repository.post(
      `${getFilteredGroupsOfUser}`,
      csencrypt.encrypt(JSON.stringify(payload))
    );
    return { data: csencrypt.decrypt(data) };
  },
  async getAllGroups(payload) {
    payload.communityData.uniqueId = localStorage.getItem("biri");
    let { data } = await Repository.post(
      `${getAllGroups}`,
      csencrypt.encrypt(JSON.stringify(payload))
    );
    return { data: csencrypt.decrypt(data) };
  },
  async searchAllGroups(payload) {
    payload.communityData.uniqueId = localStorage.getItem("biri");
    let { data } = await Repository.post(
      `${searchAllGroups}`,
      csencrypt.encrypt(JSON.stringify(payload))
    );
    return { data: csencrypt.decrypt(data) };
  },
  async addGroup(payload) {
    payload.communityData.uniqueId = localStorage.getItem("biri");
    let { data } = await Repository.post(
      `${addGroup}`,
      csencrypt.encrypt(JSON.stringify(payload))
    );
    return { data: csencrypt.decrypt(data) };
  },
  async inviteUserToGroup(payload) {
    payload.communityData.uniqueId = localStorage.getItem("biri");
    let { data } = await Repository.post(
      `${inviteUserToGroup}`,
      csencrypt.encrypt(JSON.stringify(payload))
    );
    return { data: csencrypt.decrypt(data) };
  },
  async getUsersInGroup(payload) {
    payload.communityData.uniqueId = localStorage.getItem("biri");
    let { data } = await Repository.post(
      `${getUsersInGroup}`,
      csencrypt.encrypt(JSON.stringify(payload))
    );
    return { data: csencrypt.decrypt(data) };
  },
  async getUsersInPublicGroup(payload) {
    payload.communityData.uniqueId = localStorage.getItem("biri");
    let { data } = await Repository.post(
      `${getUsersInPublicGroup}`,
      csencrypt.encrypt(JSON.stringify(payload))
    );
    return { data: csencrypt.decrypt(data) };
  },
  async editGroup(payload) {
    payload.communityData.uniqueId = localStorage.getItem("biri");
    let { data } = await Repository.post(
      `${editGroup}`,
      csencrypt.encrypt(JSON.stringify(payload))
    );
    return { data: csencrypt.decrypt(data) };
  },
  async getJoinRequestsOfGroup(payload) {
    payload.communityData.uniqueId = localStorage.getItem("biri");
    let { data } = await Repository.post(
      `${getJoinRequestsOfGroup}`,
      csencrypt.encrypt(JSON.stringify(payload))
    );
    return { data: csencrypt.decrypt(data) };
  },
  async invitationResponseOfGroup(payload) {
    payload.communityData.uniqueId = localStorage.getItem("biri");
    let { data } = await Repository.post(
      `${invitationResponseOfGroup}`,
      csencrypt.encrypt(JSON.stringify(payload))
    );
    return { data: csencrypt.decrypt(data) };
  },
  async invitationResponseOfUser(payload) {
    payload.communityData.uniqueId = localStorage.getItem("biri");
    let { data } = await Repository.post(
      `${invitationResponseOfUser}`,
      csencrypt.encrypt(JSON.stringify(payload))
    );
    return { data: csencrypt.decrypt(data) };
  },
  async getGroupsOfUserWithPostingRights(payload) {
    payload.communityData.uniqueId = localStorage.getItem("biri");
    let { data } = await Repository.post(
      `${getGroupsOfUserWithPostingRights}`,
      csencrypt.encrypt(JSON.stringify(payload))
    );
    return { data: csencrypt.decrypt(data) };
  },
  async getUniverseGroup(payload) {
    payload.communityData.uniqueId = localStorage.getItem("biri");
    let { data } = await Repository.post(
      `${getUniverseGroup}`,
      csencrypt.encrypt(JSON.stringify(payload))
    );
    return { data: csencrypt.decrypt(data) };
  },
  async requestToJoinGroup(payload) {
    payload.communityData.uniqueId = localStorage.getItem("biri");
    let { data } = await Repository.post(
      `${requestToJoinGroup}`,
      csencrypt.encrypt(JSON.stringify(payload))
    );
    return { data: csencrypt.decrypt(data) };
  },
  async getGroupsOfUser(payload) {
    payload.communityData.uniqueId = localStorage.getItem("biri");
    let { data } = await Repository.post(
      `${getGroupsOfUser}`,
      csencrypt.encrypt(JSON.stringify(payload))
    );
    return { data: csencrypt.decrypt(data) };
  },
  async getPublicGroups(payload) {
    payload.communityData.uniqueId = localStorage.getItem("biri");
    let { data } = await Repository.post(
      `${getPublicGroups}`,
      csencrypt.encrypt(JSON.stringify(payload))
    );
    return { data: csencrypt.decrypt(data) };
  },
  async getPrivateGroups(payload) {
    payload.communityData.uniqueId = localStorage.getItem("biri");
    let { data } = await Repository.post(
      `${getPrivateGroups}`,
      csencrypt.encrypt(JSON.stringify(payload))
    );
    return { data: csencrypt.decrypt(data) };
  },
  async getSystemGroups(payload) {
    payload.communityData.uniqueId = localStorage.getItem("biri");
    let { data } = await Repository.post(
      `${getSystemGroups}`,
      csencrypt.encrypt(JSON.stringify(payload))
    );
    return { data: csencrypt.decrypt(data) };
  },
  async getRecommendedGroups(payload) {
    payload.communityData.uniqueId = localStorage.getItem("biri");
    let { data } = await Repository.post(
      `${getRecommendedGroups}`,
      csencrypt.encrypt(JSON.stringify(payload))
    );
    return { data: csencrypt.decrypt(data) };
  },
  async getMembersForGroupChat(payload) {
    payload.communityData.uniqueId = localStorage.getItem("biri");
    let { data } = await Repository.post(
      `${getMembersForGroupChat}`,
      csencrypt.encrypt(JSON.stringify(payload))
    );
    return { data: csencrypt.decrypt(data) };
  },
  async insertAllMembersInGroup(payload) {
    payload.communityData.uniqueId = localStorage.getItem("biri");
    let { data } = await Repository.post(
      `${insertAllMembersInGroup}`,
      csencrypt.encrypt(JSON.stringify(payload))
    );
    return { data: csencrypt.decrypt(data) };
  },
  async changePostingRights(payload) {
    payload.communityData.uniqueId = localStorage.getItem("biri");
    let { data } = await Repository.post(
      `${changePostingRights}`,
      csencrypt.encrypt(JSON.stringify(payload))
    );
    return { data: csencrypt.decrypt(data) };
  },
  async changeGroupStatus(payload) {
    payload.communityData.uniqueId = localStorage.getItem("biri");
    let { data } = await Repository.post(
      `${changeGroupStatus}`,
      csencrypt.encrypt(JSON.stringify(payload))
    );

    return { data: csencrypt.decrypt(data) };
  },
  async getGroupByName(payload) {
    payload.communityData.uniqueId = localStorage.getItem("biri");
    let { data } = await Repository.post(
      `${getGroupByName}`,
      csencrypt.encrypt(JSON.stringify(payload))
    );

    return { data: csencrypt.decrypt(data) };
  },
  async deleteGroup(payload) {
  
    payload.communityData.uniqueId = localStorage.getItem("biri");
    let { data } = await Repository.post(
      `${deleteGroup}`,
      csencrypt.encrypt(JSON.stringify(payload))
    );

    return { data: csencrypt.decrypt(data) };
  },
  async getUsersFromGroupIDs(payload) {
    payload.communityData.uniqueId = localStorage.getItem("biri");
    let { data } = await Repository.post(
      `${getUsersFromGroupIDs}`,
      csencrypt.encrypt(JSON.stringify(payload))
    );

    return { data: csencrypt.decrypt(data) };
  },
  async exportUsersInGroup(payload) {
    payload.communityData.uniqueId = localStorage.getItem("biri");
    let { data } = await Repository.post(
      `${exportUsersInGroup}`,
      csencrypt.encrypt(JSON.stringify(payload))
    );

    return { data: csencrypt.decrypt(data) };
  },

  async getCommunityOwner(payload) {
    payload.communityData.uniqueId = localStorage.getItem("biri");
    let { data } = await Repository.post(
      `${getCommunityOwner}`,
      csencrypt.encrypt(JSON.stringify(payload))
    );

    return { data: csencrypt.decrypt(data) };
  },
  async getCommunityGroup(payload) {
    payload.communityData.uniqueId = localStorage.getItem("biri");
    let { data } = await Repository.post(
      `${getCommunityGroup}`,
      csencrypt.encrypt(JSON.stringify(payload))
    );

    return { data: csencrypt.decrypt(data) };
  },
};
