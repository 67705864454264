export default {
  getEvent: state => eventId =>
    state.events.find(event => event.id === eventId),
  getLastWorkingDays: state => index => state.lastWorkingDays.slice(index, 12),
  getFilteredEvents: state => (country, priority, actual) => {
    return state.events.filter(
      event =>
        (priority && priority !== "All"
          ? event.importance === priority
          : true) &&
        (country && country !== "All" ? event.country === country : true) &&
        (actual && actual !== "All" ? event.actualValue : true)
    );
  },
  getEventNotification: state => eventId =>
    state.eventNotifications.filter(event => event.eventID === eventId)
};
