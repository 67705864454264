import axios from "axios";
import Swal from "sweetalert2";

// You can use your own logic to set your local or production domain
// const baseDomain = "https://jsonplaceholder.typicode.com";
const baseDomain = process.env.VUE_APP_BASE_URL_COR_API;
// const baseDomain = "http://localhost:9890/";
//const baseDomain = "http://35.207.243.82/";
// The base URL is empty this time due we are using the jsonplaceholder API
const baseURL = `${baseDomain}`;
const userInfoLocalStorage = localStorage.getItem("token");

const API = axios.create({
  baseURL: baseURL,
  headers: {
    token: userInfoLocalStorage,
    // "Cache-Control": ["no-cache", "maxage=0", "no-store"],
  },
});

API.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");

    if (token) {
      config.headers.token = token;
    } else {
      delete API.defaults.headers.common.token;
    }
    return config;
  },

  (error) => Promise.reject(error)
);
var flag = 0;
API.interceptors.response.use(
  (response) => {
    if (response.data.message == "INVALID TOKEN" && flag == 0) {
      //window.alert("Your Session Expired");
      Swal.fire({
        icon: "error",
        showConfirmButton: false,
        timer: 1500,
        text: "Session Expired",
      });
      window.location.replace(
        window.location.origin + "/pages/customadminlogin"
      );
      flag = 1;
      return 0;
    }
    return response;
  },

  (error) => Promise.reject(error)
);

export default API;
