/*=========================================================================================
  File Name: moduleChatActions.js
  Description: Chat Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
import { RepositoryFactory } from "@/Core/Apicall/RepositoryFactory";
const postsRepository = RepositoryFactory.get("posts");

export default {
  async deletePostComment({}, payload) {
    const { data } = await postsRepository.deleteComment(payload);
    return data;
  },
  async deletePostReply({}, payload) {
    const { data } = await postsRepository.deleteReply(payload);
    return data;
  },
  
  // Manage Author //
  async Kotakgetauthor({commit},payload){
    const {data} = await postsRepository.Kotakgetauthor(payload)
    commit("GET_AUTHOR", data);
    return data
  },
  async Kotakgaddauthor({},payload){
    const data = await postsRepository.Kotakgaddauthor(payload)
    return data
  },
  async Kotakgeditauthor({},payload){
    const data = await postsRepository.Kotakgeditauthor(payload)
    return data
  },
  async Kotakdeleteauthor({},payload){
    const data = await postsRepository.Kotakdeleteauthor(payload)
    return data
  },
  // Manage Category //
  async KotakgetCategory({commit},payload){
    const {data} = await postsRepository.KotakgetCategory(payload)
    commit("GET_CATEGORY", data);
    return data
  },
  async KotakgaddCategory({},payload){
    const data = await postsRepository.KotakgaddCategory(payload)
    return data
  },
  async KotakgeditCategory({},payload){
    const data = await postsRepository.KotakgeditCategory(payload)
    return data
  },
  async KotakdeleteCategory({},payload){
    const data = await postsRepository.KotakdeleteCategory(payload)
    return data
  },
};
