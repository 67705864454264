export default {
  selectedUsers(state) {
    return state.invitedUsers.filter(x => x.isSelected === true);
  },
  unselectedUsers(state) {
    return state.invitedUsers.filter(x => x.isSelected === false);
  },
  selectedUserMobiles(state) {
    return state.invitedUsers
      .filter(x => x.isSelected === true)
      .map(x => x.mobile);
  },
  selectedUserEmails(state) {
    return state.invitedUsers
      .filter(x => x.isSelected === true)
      .map(x => x.email);
  }
};
