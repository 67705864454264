/*=========================================================================================
  File Name: moduleChatState.js
  Description: Chat Module State
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  homeChannelPost: [],
  postDetail: {},
  channelPostDetail: {},
  homeChannelPodcard: [],
  objCategory: null,
  categorySlider: null,
  totalPageBlog: null,
  objCategoryPodcast: null,
  categorySliderPodcast: null,
  totalPagePodcard: null,
  author:null,
  category:null,
  scroll:false
};
