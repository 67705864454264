import Repository from "./SecureRepository";
import csencrypt from "../config/csencrypt";
// import store from "@/Core/store/store";

// const resource = "secureApi/getContactList";
const resourceAddDashboardMaster = "BrainstormDashboardApi/addDashboardMaster";
const resourceAddDashboardCards = "BrainstormDashboardApi/addDashboardCards";
const resourceAddDashboardCardWithVideo =
  "BrainstormDashboardApi/addDashboardCardWithVideo";
const resourceGetDashboardCards = "BrainstormDashboardApi/getDashboardCards";
const resourceUpdateDashboardCards =
  "BrainstormDashboardApi/updateDashboardCards";
const resourceGetUsersForDashboard =
  "BrainstormDashboardApi/getUsersForDashboard";
const resourceGetTrendingPostsForDashboard =
  "BrainstormDashboardApi/getTrendingPostsForDashboard";
const resourceGetGroupsForDashboard =
  "BrainstormDashboardApi/getGroupsForDashboard";
const resourceGetDiscussionsForDashboard =
  "BrainstormDashboardApi/getDiscussionsForDashboard";

export default {
  async addDashboardMaster(payload) {
    payload.communityData.uniqueId = localStorage.getItem("biri");
    let { data } = await Repository.post(
      `${resourceAddDashboardMaster}`,
      csencrypt.encrypt(JSON.stringify(payload))
    );
    return { data: csencrypt.decrypt(data) };
  },

  async addDashboardCards(payload) {
    payload.communityData.uniqueId = localStorage.getItem("biri");
    let { data } = await Repository.post(
      `${resourceAddDashboardCards}`,
      csencrypt.encrypt(JSON.stringify(payload))
    );
    return { data: csencrypt.decrypt(data) };
  },

  async addDashboardCardWithVideo(payload) {
    payload.communityData.uniqueId = localStorage.getItem("biri");
    return Repository.post(`${resourceAddDashboardCardWithVideo}`, payload); //multipart upload hence no encryption
  },

  async getDashboardCards(payload) {
    payload.communityData.uniqueId = localStorage.getItem("biri");
    let { data } = await Repository.post(
      `${resourceGetDashboardCards}`,
      csencrypt.encrypt(JSON.stringify(payload))
    );
    return { data: csencrypt.decrypt(data) };
  },

  async updateDashboardCards(payload) {
    payload.communityData.uniqueId = localStorage.getItem("biri");
    let { data } = await Repository.post(
      `${resourceUpdateDashboardCards}`,
      csencrypt.encrypt(JSON.stringify(payload))
    );
    return { data: csencrypt.decrypt(data) };
  },

  async getUsersForDashboard(payload) {
    payload.communityData.uniqueId = localStorage.getItem("biri");
    let { data } = await Repository.post(
      `${resourceGetUsersForDashboard}`,
      csencrypt.encrypt(JSON.stringify(payload))
    );
    return { data: csencrypt.decrypt(data) };
  },

  async getTrendingPostsForDashboard(payload) {
    payload.communityData.uniqueId = localStorage.getItem("biri");
    let { data } = await Repository.post(
      `${resourceGetTrendingPostsForDashboard}`,
      csencrypt.encrypt(JSON.stringify(payload))
    );
    return { data: csencrypt.decrypt(data) };
  },

  async getGroupsForDashboard(payload) {
    //  payload.communityData.uniqueId = store.state.uniqueDeviceId;
    payload.communityData.uniqueId = localStorage.getItem("biri");

    let { data } = await Repository.post(
      `${resourceGetGroupsForDashboard}`,
      csencrypt.encrypt(JSON.stringify(payload))
    );

    // if (data.status === 500) return localStorage.removeItem('token');
    // if (data.status === 500) {
    //   alert("Your session expired");
    //   localStorage.removeItem('token');
    //   location.reload();
    //   return false
    // }
    return { data: csencrypt.decrypt(data) };
  },

  async getDiscussionsForDashboard(payload) {
    payload.communityData.uniqueId = localStorage.getItem("biri");
    let { data } = await Repository.post(
      `${resourceGetDiscussionsForDashboard}`,
      csencrypt.encrypt(JSON.stringify(payload))
    );
    return { data: csencrypt.decrypt(data) };
  }
};
