/*=========================================================================================
  File Name: getters.js
  Description: Vuex Store - getters
  ----------------------------------------------------------------------------------------
==========================================================================================*/

const getters = {
  // COMPONENT
  // vx-autosuggest
  // starredPages: state => state.navbarSearchAndPinList.data.filter((page) => page.highlightAction),
  windowBreakPoint: state => {
    // This should be same as tailwind. So, it stays in sync with tailwind utility classes
    if (state.windowWidth >= 1200) return "xl";
    else if (state.windowWidth >= 992) return "lg";
    else if (state.windowWidth >= 768) return "md";
    else if (state.windowWidth >= 576) return "sm";
    else return "xs";
  },
  recoomendedGroupDataOfUser: state => () => state.recommendedgroupArray,
  userData: state => () => state.user,
  postPopup: state => () => state.postPopupData,
  getuniqueDeviceId: state => () => state.uniqueDeviceId
};

export default getters;
