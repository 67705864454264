import Repository from "@/Core/Apicall/Repository.js";

const resourceAddNote = "Kotak/note/add";
const resourceGetNote = "Kotak/note/get";
const resourceDeleteNote = "Kotak/note/delete";
const resourceUpdateNote = "Kotak/note/update";

export default {
  addNote(payload) {
    return Repository.post(`${resourceAddNote}`, payload);
  },
  getNote(payload) {
    return Repository.post(`${resourceGetNote}`, payload);
  },
  deleteNote(payload) {
    return Repository.post(`${resourceDeleteNote}`, payload);
  },
  updateNote(payload) {
    return Repository.post(`${resourceUpdateNote}`, payload);
  }
};
