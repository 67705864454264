const crypto = require("crypto");
// const algorithm = "aes-256-cbc";
// const key = crypto.randomBytes(32);
// const key = crypto.randomBytes(32);
const iv = crypto.randomBytes(16);
const additionalKey =
  "8e3d2ce34c5bcc8aaee8b5275569966bb7800576c764bffeed260fdd0682a04e";

export default {
  encrypt(text) {
    let cipher = crypto.createCipheriv(
      "aes-256-cbc",
      Buffer.from(additionalKey, "hex"),
      iv
    );
    let encrypted = cipher.update(text);
    encrypted = Buffer.concat([encrypted, cipher.final()]);
    return { iv: iv.toString("hex"), encryptedData: encrypted.toString("hex") };
  },

  decrypt(text) {
    // console.log({ text });
    try {
      // if (text.RefreshToken) {
      //   localStorage.setItem("token", text.RefreshToken);
      // }
      text = text.Data;
      let iv = Buffer.from(text.iv, "hex");
      let encryptedText = Buffer.from(text.encryptedData, "hex");
      let decipher = crypto.createDecipheriv(
        "aes-256-cbc",
        Buffer.from(additionalKey, "hex"),
        iv
      );
      let decrypted = decipher.update(encryptedText);
      decrypted = Buffer.concat([decrypted, decipher.final()]);
      // console.log(JSON.parse(decrypted.toString()), "slslsl");

      return JSON.parse(decrypted.toString());
    } catch (error) {
      // alert("Your session expired");
      // localStorage.removeItem("token");
      // location.replace(window.document.location.origin);
      return false;
    }
  }
};
